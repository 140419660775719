export const GENERATED_MAPPING = {
    'com.dataiku.dip.experimenttracking.Model' : 'Model',
    'com.dataiku.dip.pivot.backend.model.ColumnSummary$MeaningInfo' : 'ColumnSummary.MeaningInfo',
    'com.dataiku.dip.pivot.backend.model.ColumnSummary$UsableColumn' : 'ColumnSummary.UsableColumn',
    'com.dataiku.dip.pivot.backend.model.ColumnSummary' : 'ColumnSummary',
    'com.dataiku.dip.analysis.ml.SMStatus' : 'SMStatus',
    'com.dataiku.dip.labeling.score.LabelingScoreComputer$AnswerScore' : 'LabelingScoreComputer.AnswerScore',
    'com.dataiku.dip.analysis.model.prediction.ResolvedTimeseriesForecastingPreprocessingParams' : 'ResolvedTimeseriesForecastingPreprocessingParams',
    'com.dataiku.dip.analysis.model.prediction.ActualModelParameters' : 'ActualModelParameters',
    'com.dataiku.dip.shaker.server.SerializedMemTableV2$MeaningResult' : 'SerializedMemTableV2.MeaningResult',
    'com.dataiku.dip.shaker.server.SerializedMemTableV2$Header$ProgressBarChunk' : 'SerializedMemTableV2.Header.ProgressBarChunk',
    'com.dataiku.dip.shaker.server.SerializedMemTableV2$Header$ProgressBarType' : 'SerializedMemTableV2.Header.ProgressBarType',
    'com.dataiku.dip.shaker.server.SerializedMemTableV2$Header' : 'SerializedMemTableV2.Header',
    'com.dataiku.dip.shaker.server.SerializedMemTableV2' : 'SerializedMemTableV2',
    'com.dataiku.dip.projects.importexport.model.ProjectExportOptions' : 'ProjectExportOptions',
    'com.dataiku.dip.projects.importexport.model.ProjectExportOptions$IncludedDatasetData' : 'ProjectExportOptions.IncludedDatasetData',
    'com.dataiku.dip.projects.importexport.model.ProjectExportOptions$IncludedManagedFolder' : 'ProjectExportOptions.IncludedManagedFolder',
    'com.dataiku.dip.projects.importexport.model.ProjectExportOptions$IncludedSavedModel' : 'ProjectExportOptions.IncludedSavedModel',
    'com.dataiku.dip.projects.importexport.model.ProjectExportOptions$IncludedModelEvaluationStore' : 'ProjectExportOptions.IncludedModelEvaluationStore',
    'com.dataiku.dip.projects.importexport.model.ProjectExportOptions$IncludedLabelingTask' : 'ProjectExportOptions.IncludedLabelingTask',
    'com.dataiku.dip.projects.importexport.model.ProjectExportOptions$IncludedCodeStudio' : 'ProjectExportOptions.IncludedCodeStudio',
    'com.dataiku.dip.eda.worksheets.cards.UnitRootTestCard$UnitRootTestCardResult' : 'UnitRootTestCard.UnitRootTestCardResult',
    'com.dataiku.dip.eda.worksheets.cards.UnitRootTestCard' : 'UnitRootTestCard',
    'com.dataiku.dip.eda.compute.computations.bivariate.Chi2IndTest$Chi2IndTestResult' : 'Chi2IndTest.Chi2IndTestResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.Chi2IndTest' : 'Chi2IndTest',
    'com.dataiku.dip.analysis.ml.MLDiagnostics' : 'MLDiagnostics',
    'com.dataiku.dip.analysis.ml.MLDiagnostics$DoctorDiagnostic' : 'MLDiagnostics.DoctorDiagnostic',
    'com.dataiku.dip.code.ProjectCodeEnvsSelection$ProjectCodeEnvSelection' : 'ProjectCodeEnvsSelection.ProjectCodeEnvSelection',
    'com.dataiku.dip.code.ProjectCodeEnvsSelection' : 'ProjectCodeEnvsSelection',
    'com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard' : 'TimeSeriesCard',
    'com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesCardResult' : 'TimeSeriesCard.TimeSeriesCardResult',
    'com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesIdentifier' : 'TimeSeriesCard.TimeSeriesIdentifier',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.NumericalHistogramCard' : 'NumericalHistogramCard',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.NumericalHistogramCard$NumericalHistogramCardResult' : 'NumericalHistogramCard.NumericalHistogramCardResult',
    'com.dataiku.dip.pivot.backend.model.AxisDef' : 'AxisDef',
    'com.dataiku.dip.pivot.backend.model.AxisDef$Type' : 'AxisDef.Type',
    'com.dataiku.dip.analysis.model.core.ResolvedPreprocessingParams$ReductionParams' : 'ResolvedPreprocessingParams.ReductionParams',
    'com.dataiku.dip.analysis.model.core.ResolvedPreprocessingParams' : 'ResolvedPreprocessingParams',
    'com.dataiku.dip.eda.worksheets.cards.common.ColumnCard$ColumnCardResult' : 'ColumnCard.ColumnCardResult',
    'com.dataiku.dip.eda.worksheets.cards.common.ColumnCard' : 'ColumnCard',
    'com.dataiku.dip.pivot.backend.model.AxisSortPrune' : 'AxisSortPrune',
    'com.dataiku.dip.pivot.backend.model.AxisSortPrune$MeasureFilter' : 'AxisSortPrune.MeasureFilter',
    'com.dataiku.dip.pivot.backend.model.AxisSortPrune$SortType' : 'AxisSortPrune.SortType',
    'com.dataiku.dip.eda.worksheets.cards.fragments.TimeInfoFragment$StepInfo' : 'TimeInfoFragment.StepInfo',
    'com.dataiku.dip.eda.worksheets.cards.fragments.TimeInfoFragment' : 'TimeInfoFragment',
    'com.dataiku.dip.eda.compute.distributions.Distribution' : 'Distribution',
    'com.dataiku.dip.eda.compute.distributions.Distribution$FittedDistribution' : 'Distribution.FittedDistribution',
    'com.dataiku.dip.datasets.DatasetSelection' : 'DatasetSelection',
    'com.dataiku.dip.datasets.DatasetSelection$PartitionSelectionMethod' : 'DatasetSelection.PartitionSelectionMethod',
    'com.dataiku.dip.analysis.model.prediction.PredictionMLTask$ClassicalPredictionMLTask' : 'PredictionMLTask.ClassicalPredictionMLTask',
    'com.dataiku.dip.analysis.model.prediction.PredictionMLTask$CausalPredictionMLTask' : 'PredictionMLTask.CausalPredictionMLTask',
    'com.dataiku.dip.analysis.model.prediction.PredictionMLTask$PredictionType' : 'PredictionMLTask.PredictionType',
    'com.dataiku.dip.analysis.model.prediction.PredictionMLTask$TabularPredictionMLTask' : 'PredictionMLTask.TabularPredictionMLTask',
    'com.dataiku.dip.analysis.model.prediction.PredictionMLTask$TimeseriesForecastingMLTask' : 'PredictionMLTask.TimeseriesForecastingMLTask',
    'com.dataiku.dip.analysis.model.prediction.PredictionMLTask' : 'PredictionMLTask',
    'com.dataiku.dip.analysis.model.prediction.PredictionMLTask$TimeseriesForecastingMLTask$TimeseriesEvaluationParams' : 'PredictionMLTask.TimeseriesForecastingMLTask.TimeseriesEvaluationParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionMLTask$DeepHubPredictionMLTask' : 'PredictionMLTask.DeepHubPredictionMLTask',
    'com.dataiku.dip.eda.compute.computations.univariate.SignTest1Samp' : 'SignTest1Samp',
    'com.dataiku.dip.eda.compute.computations.univariate.SignTest1Samp$SignTest1SampResult' : 'SignTest1Samp.SignTest1SampResult',
    'com.dataiku.dip.eda.compute.computations.multivariate.FetchValues$FetchValuesResult' : 'FetchValues.FetchValuesResult',
    'com.dataiku.dip.eda.compute.computations.multivariate.FetchValues' : 'FetchValues',
    'com.dataiku.dip.eda.compute.distributions.Pareto' : 'Pareto',
    'com.dataiku.dip.eda.compute.distributions.Pareto$FittedPareto' : 'Pareto.FittedPareto',
    'com.dataiku.dip.dashboards.model.Dashboard$DashboardListItem' : 'Dashboard.DashboardListItem',
    'com.dataiku.dip.dashboards.model.Dashboard' : 'Dashboard',
    'com.dataiku.dip.labeling.region.RegionElement' : 'RegionElement',
    'com.dataiku.dip.labeling.objectdetection.BoundingBox$BoxCoordinates' : 'BoundingBox.BoxCoordinates',
    'com.dataiku.dip.labeling.objectdetection.BoundingBox' : 'BoundingBox',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateHeaderCard$UnivariateHeaderCardResult' : 'UnivariateHeaderCard.UnivariateHeaderCardResult',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateHeaderCard' : 'UnivariateHeaderCard',
    'com.dataiku.dip.eda.compute.computations.univariate.Shapiro$ShapiroResult' : 'Shapiro.ShapiroResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Shapiro' : 'Shapiro',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumn' : 'SerializedShakerScript.DisplayedColumn',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$ValueColoringMode' : 'SerializedShakerScript.ValueColoringMode',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$TableColoringScheme' : 'SerializedShakerScript.TableColoringScheme',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$TableSorting' : 'SerializedShakerScript.TableSorting',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$ShakerExplorationSampleSettings' : 'SerializedShakerScript.ShakerExplorationSampleSettings',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$TableColoring' : 'SerializedShakerScript.TableColoring',
    'com.dataiku.dip.shaker.model.SerializedShakerScript' : 'SerializedShakerScript',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$ShakerOrigin' : 'SerializedShakerScript.ShakerOrigin',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$FullSampleStatisticsConfig' : 'SerializedShakerScript.FullSampleStatisticsConfig',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumnsMode' : 'SerializedShakerScript.DisplayedColumnsMode',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$ChartsEngine' : 'SerializedShakerScript.ChartsEngine',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$AnalysisColumnData' : 'SerializedShakerScript.AnalysisColumnData',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$RefreshableStreamableSelection' : 'SerializedShakerScript.RefreshableStreamableSelection',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$DisplayedColumnsSelection' : 'SerializedShakerScript.DisplayedColumnsSelection',
    'com.dataiku.dip.shaker.model.SerializedShakerScript$PreviewMode' : 'SerializedShakerScript.PreviewMode',
    'com.dataiku.dip.eda.worksheets.cards.UnitRootTestADFCard' : 'UnitRootTestADFCard',
    'com.dataiku.dip.eda.worksheets.cards.UnitRootTestADFCard$UnitRootTestADFCardResult' : 'UnitRootTestADFCard.UnitRootTestADFCardResult',
    'com.dataiku.dip.eda.worksheets.cards.FitCurveCard$FitCurveCardResult' : 'FitCurveCard.FitCurveCardResult',
    'com.dataiku.dip.eda.worksheets.cards.FitCurveCard' : 'FitCurveCard',
    'com.dataiku.dip.eda.compute.grouping.Grouping' : 'Grouping',
    'com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard' : 'MoodTest2SampCard',
    'com.dataiku.dip.eda.worksheets.cards.MoodTest2SampCard$MoodTest2SampCardResult' : 'MoodTest2SampCard.MoodTest2SampCardResult',
    'com.dataiku.dip.analysis.model.prediction.EnsembleParams' : 'EnsembleParams',
    'com.dataiku.dip.analysis.model.prediction.EnsembleParams$EnsembleMethod' : 'EnsembleParams.EnsembleMethod',
    'com.dataiku.dip.eda.compute.computations.timeseries.PACF$Method' : 'PACF.Method',
    'com.dataiku.dip.eda.compute.computations.timeseries.PACF' : 'PACF',
    'com.dataiku.dip.eda.compute.computations.timeseries.PACF$PACFResult' : 'PACF.PACFResult',
    'com.dataiku.dip.analysis.model.PreprocessingReport$CategoricalEncoding' : 'PreprocessingReport.CategoricalEncoding',
    'com.dataiku.dip.analysis.model.PreprocessingReport' : 'PreprocessingReport',
    'com.dataiku.dip.eda.worksheets.cards.common.Series' : 'Series',
    'com.dataiku.dip.server.services.TaggableObjectsService$TaggableObject' : 'TaggableObjectsService.TaggableObject',
    'com.dataiku.dip.server.services.TaggableObjectsService$TaggableObjectRef' : 'TaggableObjectsService.TaggableObjectRef',
    'com.dataiku.dip.server.services.TaggableObjectsService$TaggableListItem' : 'TaggableObjectsService.TaggableListItem',
    'com.dataiku.dip.pivot.backend.model.FilterFacet' : 'FilterFacet',
    'com.dataiku.dip.pivot.backend.model.FilterFacet$Val' : 'FilterFacet.Val',
    'com.dataiku.dip.pivot.backend.model.FilterFacet$HistogramBar' : 'FilterFacet.HistogramBar',
    'com.dataiku.dip.coremodel.ObjectCustomMetadata' : 'ObjectCustomMetadata',
    'com.dataiku.dip.shaker.server.SerializedTableChunk' : 'SerializedTableChunk',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams$PretrainedModel' : 'DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams.PretrainedModel',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$RandomCropParams' : 'DeepHubPreTrainModelingParams.RandomCropParams',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageAugmentationParams' : 'DeepHubPreTrainModelingParams.ImageAugmentationParams',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageClassificationPreTrainModelingParams$PretrainedModel' : 'DeepHubPreTrainModelingParams.ImageClassificationPreTrainModelingParams.PretrainedModel',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ColorJitterParams' : 'DeepHubPreTrainModelingParams.ColorJitterParams',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$TrainableLayers$TrainableLayersMode' : 'DeepHubPreTrainModelingParams.TrainableLayers.TrainableLayersMode',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageAugmentationParam' : 'DeepHubPreTrainModelingParams.ImageAugmentationParam',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ImageClassificationPreTrainModelingParams' : 'DeepHubPreTrainModelingParams.ImageClassificationPreTrainModelingParams',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$AffineParams' : 'DeepHubPreTrainModelingParams.AffineParams',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$EarlyStopping' : 'DeepHubPreTrainModelingParams.EarlyStopping',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$Optimizer' : 'DeepHubPreTrainModelingParams.Optimizer',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$TrainableLayers' : 'DeepHubPreTrainModelingParams.TrainableLayers',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams' : 'DeepHubPreTrainModelingParams',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$ObjectDetectionPreTrainModelingParams' : 'DeepHubPreTrainModelingParams.ObjectDetectionPreTrainModelingParams',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$LrScheduler' : 'DeepHubPreTrainModelingParams.LrScheduler',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPreTrainModelingParams$RotateParams' : 'DeepHubPreTrainModelingParams.RotateParams',
    'com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard$CorrelationMetric' : 'CorrelationMatrixCard.CorrelationMetric',
    'com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard' : 'CorrelationMatrixCard',
    'com.dataiku.dip.eda.worksheets.cards.CorrelationMatrixCard$CorrelationMatrixCardResult' : 'CorrelationMatrixCard.CorrelationMatrixCardResult',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.Abstract2DPivotCard' : 'Abstract2DPivotCard',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelDetails' : 'DeepHubPredictionModelDetails',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard$StatsToCompute' : 'UnivariateSummaryStatsCard.StatsToCompute',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard$UnivariateSummaryStatsCardResult' : 'UnivariateSummaryStatsCard.UnivariateSummaryStatsCardResult',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateSummaryStatsCard' : 'UnivariateSummaryStatsCard',
    'com.dataiku.dip.pivot.backend.model.maps.PivotTableWebappRequest' : 'PivotTableWebappRequest',
    'com.dataiku.dip.analysis.model.prediction.TimeOrderingParams' : 'TimeOrderingParams',
    'com.dataiku.dip.shaker.model.ProcessorScriptStep' : 'ProcessorScriptStep',
    'com.dataiku.dip.eda.compute.computations.common.MultiComputation' : 'MultiComputation',
    'com.dataiku.dip.eda.compute.computations.common.MultiComputation$MultiComputationResult' : 'MultiComputation.MultiComputationResult',
    'com.dataiku.dip.eda.worksheets.cards.TTest2SampCard' : 'TTest2SampCard',
    'com.dataiku.dip.eda.worksheets.cards.TTest2SampCard$TTest2SampCardResult' : 'TTest2SampCard.TTest2SampCardResult',
    'com.dataiku.dip.pivot.backend.model.density.PT2DDensityRequest' : 'PT2DDensityRequest',
    'com.dataiku.dip.coremodel.ExposedObject' : 'ExposedObject',
    'com.dataiku.dip.coremodel.ExposedObject$Rule' : 'ExposedObject.Rule',
    'com.dataiku.dip.analysis.model.clustering.PreTrainClusteringModelingParams$Algorithm' : 'PreTrainClusteringModelingParams.Algorithm',
    'com.dataiku.dip.analysis.model.clustering.PreTrainClusteringModelingParams' : 'PreTrainClusteringModelingParams',
    'com.dataiku.dip.projects.importexport.model.BundleExporterSettings' : 'BundleExporterSettings',
    'com.dataiku.dip.labeling.VerifiedLabelingAnswer' : 'VerifiedLabelingAnswer',
    'com.dataiku.dip.eda.compute.filtering.IntervalFilter$ClosedMode' : 'IntervalFilter.ClosedMode',
    'com.dataiku.dip.eda.compute.filtering.IntervalFilter' : 'IntervalFilter',
    'com.dataiku.dip.server.datasets.DatasetsCRUDController$DatasetListItem' : 'DatasetsCRUDController.DatasetListItem',
    'com.dataiku.dip.server.datasets.DatasetsCRUDController$RecipeLink' : 'DatasetsCRUDController.RecipeLink',
    'com.dataiku.dip.pivot.backend.model.RowFilter' : 'RowFilter',
    'com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard$KsTest2SampCardResult' : 'KsTest2SampCard.KsTest2SampCardResult',
    'com.dataiku.dip.eda.worksheets.cards.KsTest2SampCard' : 'KsTest2SampCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard$MosaicPlotData' : 'MosaicPlotCard.MosaicPlotData',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard' : 'MosaicPlotCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.MosaicPlotCard$MosaicPlotCardResult' : 'MosaicPlotCard.MosaicPlotCardResult',
    'com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiCondition' : 'FilterDesc.FilterUiCondition',
    'com.dataiku.dip.dataflow.exec.filter.FilterDesc$ExpressionLanguage' : 'FilterDesc.ExpressionLanguage',
    'com.dataiku.dip.dataflow.exec.filter.FilterDesc' : 'FilterDesc',
    'com.dataiku.dip.dataflow.exec.filter.FilterDesc$FilterUiData' : 'FilterDesc.FilterUiData',
    'com.dataiku.dip.dashboards.model.ui.EnrichedDashboardPage$InsightAccessState' : 'EnrichedDashboardPage.InsightAccessState',
    'com.dataiku.dip.pivot.frontend.model.MeasureDef' : 'MeasureDef',
    'com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams' : 'CatFeaturePreprocessingParams',
    'com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$ImpactMethod' : 'CatFeaturePreprocessingParams.ImpactMethod',
    'com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$DummyDroppingMethod' : 'CatFeaturePreprocessingParams.DummyDroppingMethod',
    'com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$FrequencyDefault' : 'CatFeaturePreprocessingParams.FrequencyDefault',
    'com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$MissingHandlingMethod' : 'CatFeaturePreprocessingParams.MissingHandlingMethod',
    'com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$CategoryHandlingMethod' : 'CatFeaturePreprocessingParams.CategoryHandlingMethod',
    'com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$DummyClippingMethod' : 'CatFeaturePreprocessingParams.DummyClippingMethod',
    'com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$OrdinalOrder' : 'CatFeaturePreprocessingParams.OrdinalOrder',
    'com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$CatImputationMethod' : 'CatFeaturePreprocessingParams.CatImputationMethod',
    'com.dataiku.dip.analysis.model.preprocessing.CatFeaturePreprocessingParams$OrdinalDefault' : 'CatFeaturePreprocessingParams.OrdinalDefault',
    'com.dataiku.dip.pivot.backend.model.maps.PTMapRawGeometryResponse' : 'PTMapRawGeometryResponse',
    'com.dataiku.dip.experimenttracking.Run' : 'Run',
    'com.dataiku.dip.partitioning.PartitioningScheme' : 'PartitioningScheme',
    'com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard' : 'ShapiroNormalityTestCard',
    'com.dataiku.dip.eda.worksheets.cards.ShapiroNormalityTestCard$ShapiroNormalityTestCardResult' : 'ShapiroNormalityTestCard.ShapiroNormalityTestCardResult',
    'com.dataiku.dip.labeling.BaseLabelingAnswer' : 'BaseLabelingAnswer',
    'com.dataiku.dip.analysis.model.prediction.BinaryClassificationModelPerf$CalibrationBin' : 'BinaryClassificationModelPerf.CalibrationBin',
    'com.dataiku.dip.analysis.model.prediction.BinaryClassificationModelPerf$ClassProbabilityDensityData' : 'BinaryClassificationModelPerf.ClassProbabilityDensityData',
    'com.dataiku.dip.analysis.model.prediction.BinaryClassificationModelPerf$RocVizBin' : 'BinaryClassificationModelPerf.RocVizBin',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SGDRegressionHyperparametersSpace' : 'PredictionModelingParams.SGDRegressionHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SeasonalNaiveSpace' : 'PredictionModelingParams.SeasonalNaiveSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GridSearchParams$Strategy' : 'PredictionModelingParams.GridSearchParams.Strategy',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibDecisionTreeGridParams' : 'PredictionModelingParams.MLLibDecisionTreeGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$CausalLearningMethod' : 'PredictionModelingParams.CausalLearningMethod',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$TrivialIdentityTimeseriesSpace' : 'PredictionModelingParams.TrivialIdentityTimeseriesSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibRfImpurity' : 'PredictionModelingParams.MLLibRfImpurity',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$HyperparametersSpace' : 'PredictionModelingParams.HyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$RidgeSelectAlphaMode' : 'PredictionModelingParams.RidgeSelectAlphaMode',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibCustomGridParams' : 'PredictionModelingParams.MLLibCustomGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$H2OGBMGridParams' : 'PredictionModelingParams.H2OGBMGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GpuCodeParams' : 'PredictionModelingParams.GpuCodeParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SVMHyperparametersSpace' : 'PredictionModelingParams.SVMHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GridSearchParams$BayesianOptimizer' : 'PredictionModelingParams.GridSearchParams.BayesianOptimizer',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$RandomForestHyperparametersSpace' : 'PredictionModelingParams.RandomForestHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$H2ORandomForestGridParams' : 'PredictionModelingParams.H2ORandomForestGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$CustomPythonPluginParams' : 'PredictionModelingParams.CustomPythonPluginParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GluonTSNPTSForecasterSpace' : 'PredictionModelingParams.GluonTSNPTSForecasterSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$VerticaLogisticRegParams' : 'PredictionModelingParams.VerticaLogisticRegParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$NeuralNetworkHyperparametersSpace' : 'PredictionModelingParams.NeuralNetworkHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LightGBMHyperParametersSpace' : 'PredictionModelingParams.LightGBMHyperParametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibRfFeatureSubsetStrategy' : 'PredictionModelingParams.MLLibRfFeatureSubsetStrategy',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SGDClassificationHyperparametersSpace' : 'PredictionModelingParams.SGDClassificationHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GBTRegressionHyperparametersSpace' : 'PredictionModelingParams.GBTRegressionHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GluonTSDeepARSpace' : 'PredictionModelingParams.GluonTSDeepARSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LogisticRegressionHyperparametersSpace' : 'PredictionModelingParams.LogisticRegressionHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibNaiveBayesGridParams' : 'PredictionModelingParams.MLLibNaiveBayesGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams' : 'PredictionModelingParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$H2ODeepLearningGridParams' : 'PredictionModelingParams.H2ODeepLearningGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibLogisticRegressionGridParams' : 'PredictionModelingParams.MLLibLogisticRegressionGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$TreeSelectionMode' : 'PredictionModelingParams.TreeSelectionMode',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SGDHyperparametersSpace' : 'PredictionModelingParams.SGDHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibTreesEnsembleGridParams' : 'PredictionModelingParams.MLLibTreesEnsembleGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$RidgeRegressionHyperparametersSpace' : 'PredictionModelingParams.RidgeRegressionHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$VerticaLinearRegParams' : 'PredictionModelingParams.VerticaLinearRegParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GBTClassificationHyperparametersSpace' : 'PredictionModelingParams.GBTClassificationHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$DecisionTreeHyperparametersSpace' : 'PredictionModelingParams.DecisionTreeHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$KNNAlgorithm' : 'PredictionModelingParams.KNNAlgorithm',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LassoSelectAlphaMode' : 'PredictionModelingParams.LassoSelectAlphaMode',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$AutoArimaSpace' : 'PredictionModelingParams.AutoArimaSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LarsHyperparametersSpace' : 'PredictionModelingParams.LarsHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GradientBoostedTreeHyperparametersSpace' : 'PredictionModelingParams.GradientBoostedTreeHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LassoHyperparametersSpace' : 'PredictionModelingParams.LassoHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GridSearchParams' : 'PredictionModelingParams.GridSearchParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$KerasCodeParams' : 'PredictionModelingParams.KerasCodeParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$MLLibLinearRegressionGridParams' : 'PredictionModelingParams.MLLibLinearRegressionGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$SeasonalLoessSpace' : 'PredictionModelingParams.SeasonalLoessSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GluonTSTransformerSpace' : 'PredictionModelingParams.GluonTSTransformerSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LeastSquareHyperparametersSpace' : 'PredictionModelingParams.LeastSquareHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$CustomPythonParams' : 'PredictionModelingParams.CustomPythonParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$H2ONaiveBayesGridParams' : 'PredictionModelingParams.H2ONaiveBayesGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$LogisticRegressionClassifierMultiClass' : 'PredictionModelingParams.LogisticRegressionClassifierMultiClass',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GluonTSSimpleFeedForwardSpace' : 'PredictionModelingParams.GluonTSSimpleFeedForwardSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GridSearchCrossValidationMode' : 'PredictionModelingParams.GridSearchCrossValidationMode',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$XGBoostHyperparametersSpace' : 'PredictionModelingParams.XGBoostHyperparametersSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$H2OGLMGridParams' : 'PredictionModelingParams.H2OGLMGridParams',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$GluonTSMQCNNSpace' : 'PredictionModelingParams.GluonTSMQCNNSpace',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelingParams$KNNHyperparametersSpace' : 'PredictionModelingParams.KNNHyperparametersSpace',
    'com.dataiku.dip.eda.compute.distributions.Exponential$FittedExponential' : 'Exponential.FittedExponential',
    'com.dataiku.dip.eda.compute.distributions.Exponential' : 'Exponential',
    'com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard$PairwiseTTestCardResult' : 'PairwiseTTestCard.PairwiseTTestCardResult',
    'com.dataiku.dip.eda.worksheets.cards.PairwiseTTestCard' : 'PairwiseTTestCard',
    'com.dataiku.dip.experimenttracking.Experiment' : 'Experiment',
    'com.dataiku.dip.eda.compute.computations.common.GroupedComputation' : 'GroupedComputation',
    'com.dataiku.dip.eda.compute.computations.common.GroupedComputation$GroupedComputationResult' : 'GroupedComputation.GroupedComputationResult',
    'com.dataiku.dip.pivot.backend.model.PivotTableTensorRequest' : 'PivotTableTensorRequest',
    'com.dataiku.dip.pivot.backend.model.PivotTableTensorRequest$DrawableElementsLimit' : 'PivotTableTensorRequest.DrawableElementsLimit',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelIntrinsicPerf$LearningCurve' : 'PredictionModelIntrinsicPerf.LearningCurve',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelIntrinsicPerf' : 'PredictionModelIntrinsicPerf',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelIntrinsicPerf$GridCell' : 'PredictionModelIntrinsicPerf.GridCell',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelIntrinsicPerf$Coefficients' : 'PredictionModelIntrinsicPerf.Coefficients',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelIntrinsicPerf$VariablesImportance' : 'PredictionModelIntrinsicPerf.VariablesImportance',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelIntrinsicPerf$PartialDependenceData' : 'PredictionModelIntrinsicPerf.PartialDependenceData',
    'com.dataiku.dip.server.services.ExploresService$DatasetChart' : 'ExploresService.DatasetChart',
    'com.dataiku.dip.analysis.ml.SparkConstants$Checkpoint' : 'SparkConstants.Checkpoint',
    'com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$FeatureInteraction' : 'FeatureGenerationParams.FeatureInteraction',
    'com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$PairwiseLinearCombinations' : 'FeatureGenerationParams.PairwiseLinearCombinations',
    'com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$CategoricalsCountTransformerGenerator' : 'FeatureGenerationParams.CategoricalsCountTransformerGenerator',
    'com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams' : 'FeatureGenerationParams',
    'com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$ManualPairwiseInteractions' : 'FeatureGenerationParams.ManualPairwiseInteractions',
    'com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$NumericalsClusteringGenerator' : 'FeatureGenerationParams.NumericalsClusteringGenerator',
    'com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$PolynomialCombinations' : 'FeatureGenerationParams.PolynomialCombinations',
    'com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$AutomatizableFeatureGenerator' : 'FeatureGenerationParams.AutomatizableFeatureGenerator',
    'com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$NumericalsClusteringGenerator$TransformationMode' : 'FeatureGenerationParams.NumericalsClusteringGenerator.TransformationMode',
    'com.dataiku.dip.analysis.model.preprocessing.FeatureGenerationParams$GeneratorBehavior' : 'FeatureGenerationParams.GeneratorBehavior',
    'com.dataiku.dip.eda.compute.computations.timeseries.TimeSeriesComputation' : 'TimeSeriesComputation',
    'com.dataiku.dip.analysis.model.prediction.TimeseriesForecastingModelDetails' : 'TimeseriesForecastingModelDetails',
    'com.dataiku.dip.analysis.model.core.AnalysisCoreParams$AnalysisChart' : 'AnalysisCoreParams.AnalysisChart',
    'com.dataiku.dip.analysis.model.core.AnalysisCoreParams$AnalysisListItem' : 'AnalysisCoreParams.AnalysisListItem',
    'com.dataiku.dip.analysis.model.core.AnalysisCoreParams' : 'AnalysisCoreParams',
    'com.dataiku.dip.analysis.model.preprocessing.TimeseriesForecastingPreprocessingParams$TimeseriesSamplingParams' : 'TimeseriesForecastingPreprocessingParams.TimeseriesSamplingParams',
    'com.dataiku.dip.analysis.model.preprocessing.TimeseriesForecastingPreprocessingParams$TimeseriesSamplingParams$TimeseriesImputeMethod' : 'TimeseriesForecastingPreprocessingParams.TimeseriesSamplingParams.TimeseriesImputeMethod',
    'com.dataiku.dip.analysis.model.preprocessing.TimeseriesForecastingPreprocessingParams' : 'TimeseriesForecastingPreprocessingParams',
    'com.dataiku.dip.analysis.model.preprocessing.TimeseriesForecastingPreprocessingParams$TimeseriesSamplingParams$DuplicateTimestampsHandlingMethod' : 'TimeseriesForecastingPreprocessingParams.TimeseriesSamplingParams.DuplicateTimestampsHandlingMethod',
    'com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$Sorting' : 'DeephubImagesDataService.Sorting',
    'com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$SortBy' : 'DeephubImagesDataService.SortBy',
    'com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ObjectDetectionPredictedFilter' : 'DeephubImagesDataService.ObjectDetectionPredictedFilter',
    'com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ComputerVisionPredictedFilter' : 'DeephubImagesDataService.ComputerVisionPredictedFilter',
    'com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$CategoryType' : 'DeephubImagesDataService.CategoryType',
    'com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$ImageClassificationPredictedFilter' : 'DeephubImagesDataService.ImageClassificationPredictedFilter',
    'com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$Category' : 'DeephubImagesDataService.Category',
    'com.dataiku.dip.analysis.ml.prediction.DeephubImagesDataService$DeepHubDesignFilterRequest' : 'DeephubImagesDataService.DeepHubDesignFilterRequest',
    'com.dataiku.dip.analysis.model.prediction.TimeseriesForecastingModelPerf$TimeseriesForecastingMetrics' : 'TimeseriesForecastingModelPerf.TimeseriesForecastingMetrics',
    'com.dataiku.dip.analysis.model.prediction.TimeseriesForecastingModelPerf' : 'TimeseriesForecastingModelPerf',
    'com.dataiku.dip.server.services.JupyterService$ActiveSession' : 'JupyterService.ActiveSession',
    'com.dataiku.dip.server.services.JupyterService$JupyterNotebookListEntry' : 'JupyterService.JupyterNotebookListEntry',
    'com.dataiku.dip.server.services.JupyterService$DisplayKernelSpec' : 'JupyterService.DisplayKernelSpec',
    'com.dataiku.dip.shaker.analysis.NumericalVariableAnalysis' : 'NumericalVariableAnalysis',
    'com.dataiku.dip.labeling.entityextraction.NamedEntity' : 'NamedEntity',
    'com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest' : 'MannKendallTest',
    'com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest$MannKendallTestResult' : 'MannKendallTest.MannKendallTestResult',
    'com.dataiku.dip.eda.compute.computations.timeseries.MannKendallTest$Trend' : 'MannKendallTest.Trend',
    'com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Fit2DDistributionResult' : 'Fit2DDistribution.Fit2DDistributionResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution$Density' : 'Fit2DDistribution.Density',
    'com.dataiku.dip.eda.compute.computations.bivariate.Fit2DDistribution' : 'Fit2DDistribution',
    'com.dataiku.dip.eda.compute.computations.common.CountGroups$CountGroupsResult' : 'CountGroups.CountGroupsResult',
    'com.dataiku.dip.eda.compute.computations.common.CountGroups' : 'CountGroups',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard' : 'BivariateSummaryStatsCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard$BivariateSummaryStatsCardResult' : 'BivariateSummaryStatsCard.BivariateSummaryStatsCardResult',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateSummaryStatsCard$StatsToCompute' : 'BivariateSummaryStatsCard.StatsToCompute',
    'com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF' : 'EmpiricalCDF',
    'com.dataiku.dip.eda.compute.computations.univariate.EmpiricalCDF$EmpiricalCDFResult' : 'EmpiricalCDF.EmpiricalCDFResult',
    'com.dataiku.dip.labeling.LabelingTaskStats$RecordStatus' : 'LabelingTaskStats.RecordStatus',
    'com.dataiku.dip.labeling.LabelingTaskStats$AnswersStat' : 'LabelingTaskStats.AnswersStat',
    'com.dataiku.dip.labeling.LabelingTaskStats' : 'LabelingTaskStats',
    'com.dataiku.dip.labeling.LabelingTaskStats$RecordsStat' : 'LabelingTaskStats.RecordsStat',
    'com.dataiku.dip.analysis.coreservices.AnalysisCRUDService$MLTaskHead' : 'AnalysisCRUDService.MLTaskHead',
    'com.dataiku.dip.projects.apps.AppsService$AppOrigin' : 'AppsService.AppOrigin',
    'com.dataiku.dip.projects.apps.AppsService$AppListItem' : 'AppsService.AppListItem',
    'com.dataiku.dip.analysis.model.prediction.MetricParams' : 'MetricParams',
    'com.dataiku.dip.analysis.model.prediction.MetricParams$CostMatrixWeights' : 'MetricParams.CostMatrixWeights',
    'com.dataiku.dip.analysis.model.prediction.MetricParams$TreatmentOptimizationMetric' : 'MetricParams.TreatmentOptimizationMetric',
    'com.dataiku.dip.analysis.model.prediction.MetricParams$ThresholdOptimizationMetric' : 'MetricParams.ThresholdOptimizationMetric',
    'com.dataiku.dip.analysis.model.prediction.MetricParams$EvaluationMetric' : 'MetricParams.EvaluationMetric',
    'com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams$CustomParams' : 'TabularPredictionPreprocessingParams.FeatureSelectionParams.CustomParams',
    'com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionMethod' : 'TabularPredictionPreprocessingParams.FeatureSelectionMethod',
    'com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams' : 'TabularPredictionPreprocessingParams.FeatureSelectionParams',
    'com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams$CorrelationParams' : 'TabularPredictionPreprocessingParams.FeatureSelectionParams.CorrelationParams',
    'com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams$RandomForestParams' : 'TabularPredictionPreprocessingParams.FeatureSelectionParams.RandomForestParams',
    'com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams' : 'TabularPredictionPreprocessingParams',
    'com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams$LassoParams' : 'TabularPredictionPreprocessingParams.FeatureSelectionParams.LassoParams',
    'com.dataiku.dip.analysis.model.preprocessing.TabularPredictionPreprocessingParams$FeatureSelectionParams$PCAParams' : 'TabularPredictionPreprocessingParams.FeatureSelectionParams.PCAParams',
    'com.dataiku.dip.dashboards.model.Tile$TileType' : 'Tile.TileType',
    'com.dataiku.dip.dashboards.model.Tile$TileResizeImageMode' : 'Tile.TileResizeImageMode',
    'com.dataiku.dip.dashboards.model.Tile' : 'Tile',
    'com.dataiku.dip.dashboards.model.Tile$TileClickAction' : 'Tile.TileClickAction',
    'com.dataiku.dip.dashboards.model.Tile$DisplayTitleMode' : 'Tile.DisplayTitleMode',
    'com.dataiku.dip.dashboards.model.Tile$Box' : 'Tile.Box',
    'com.dataiku.dip.dashboards.model.Tile$TileDisplayMode' : 'Tile.TileDisplayMode',
    'com.dataiku.dip.eda.compute.distributions.Beta$FittedBeta' : 'Beta.FittedBeta',
    'com.dataiku.dip.eda.compute.distributions.Beta' : 'Beta',
    'com.dataiku.dip.dashboards.model.Insight' : 'Insight',
    'com.dataiku.dip.dashboards.model.Insight$InsightListItem' : 'Insight.InsightListItem',
    'com.dataiku.dip.pivot.backend.model.maps.PivotTableFiltersRequest' : 'PivotTableFiltersRequest',
    'com.dataiku.dip.eda.compute.computations.NotComputedResult' : 'NotComputedResult',
    'com.dataiku.dip.labeling.Annotation' : 'Annotation',
    'com.dataiku.dip.analysis.model.prediction.ResolvedCausalPredictionPreprocessingParams' : 'ResolvedCausalPredictionPreprocessingParams',
    'com.dataiku.dip.eda.compute.utils.CompactedArray' : 'CompactedArray',
    'com.dataiku.dip.analysis.model.clustering.HeatMap' : 'HeatMap',
    'com.dataiku.dip.labeling.LabelingTask$LabelingTaskOutput' : 'LabelingTask.LabelingTaskOutput',
    'com.dataiku.dip.labeling.LabelingTask$LabelingTaskInput' : 'LabelingTask.LabelingTaskInput',
    'com.dataiku.dip.labeling.LabelingTask$ConsensusStrategy' : 'LabelingTask.ConsensusStrategy',
    'com.dataiku.dip.labeling.LabelingTask$LabelingTaskType' : 'LabelingTask.LabelingTaskType',
    'com.dataiku.dip.labeling.LabelingTask$LabelingPermissionItem' : 'LabelingTask.LabelingPermissionItem',
    'com.dataiku.dip.labeling.LabelingTask' : 'LabelingTask',
    'com.dataiku.dip.labeling.LabelingTask$InputRole' : 'LabelingTask.InputRole',
    'com.dataiku.dip.labeling.LabelingTask$OutputRole' : 'LabelingTask.OutputRole',
    'com.dataiku.dip.labeling.LabelingTask$LabelingTaskListItem' : 'LabelingTask.LabelingTaskListItem',
    'com.dataiku.dip.partitioning.TimeDimension' : 'TimeDimension',
    'com.dataiku.dip.partitioning.TimeDimension$Period' : 'TimeDimension.Period',
    'com.dataiku.dip.server.services.ProjectsService$UIProject' : 'ProjectsService.UIProject',
    'com.dataiku.dip.eda.compute.grouping.CrossGrouping$CrossGroupingResult' : 'CrossGrouping.CrossGroupingResult',
    'com.dataiku.dip.eda.compute.grouping.CrossGrouping' : 'CrossGrouping',
    'com.dataiku.dip.analysis.model.preprocessing.TextFeaturePreprocessingParams' : 'TextFeaturePreprocessingParams',
    'com.dataiku.dip.analysis.model.preprocessing.TextFeaturePreprocessingParams$StopWordsMode' : 'TextFeaturePreprocessingParams.StopWordsMode',
    'com.dataiku.dip.analysis.model.preprocessing.TextFeaturePreprocessingParams$TextHandlingMethod' : 'TextFeaturePreprocessingParams.TextHandlingMethod',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateFrequencyTableCard' : 'BivariateFrequencyTableCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateFrequencyTableCard$BivariateFrequencyTableCardResult' : 'BivariateFrequencyTableCard.BivariateFrequencyTableCardResult',
    'com.dataiku.dip.eda.compute.distributions.KDE2D' : 'KDE2D',
    'com.dataiku.dip.eda.compute.distributions.KDE2D$FittedKDE2D' : 'KDE2D.FittedKDE2D',
    'com.dataiku.dip.eda.compute.curves.PolynomialCurve$ParametrizedPolynomialCurve' : 'PolynomialCurve.ParametrizedPolynomialCurve',
    'com.dataiku.dip.eda.compute.curves.PolynomialCurve' : 'PolynomialCurve',
    'com.dataiku.dip.eda.compute.computations.univariate.MinTime$MinTimeResult' : 'MinTime.MinTimeResult',
    'com.dataiku.dip.eda.compute.computations.univariate.MinTime' : 'MinTime',
    'com.dataiku.dip.eda.worksheets.queries.DebugCard$DebugCardResult' : 'DebugCard.DebugCardResult',
    'com.dataiku.dip.eda.worksheets.queries.DebugCard' : 'DebugCard',
    'com.dataiku.dip.server.services.ITaggingService$TaggableType' : 'ITaggingService.TaggableType',
    'com.dataiku.dip.pivot.frontend.model.NADimensionDef' : 'NADimensionDef',
    'com.dataiku.dip.projects.importexport.model.ActiveBundleState' : 'ActiveBundleState',
    'com.dataiku.dip.pivot.backend.dss.LongDataTensor' : 'LongDataTensor',
    'com.dataiku.dip.containers.exec.ContainerExecSelection' : 'ContainerExecSelection',
    'com.dataiku.dip.containers.exec.ContainerExecSelection$ContainerExecMode' : 'ContainerExecSelection.ContainerExecMode',
    'com.dataiku.dip.pivot.backend.dss.IndexedDataList' : 'IndexedDataList',
    'com.dataiku.dip.pivot.backend.dss.IndexedDataList$Entry' : 'IndexedDataList.Entry',
    'com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams$ReductionParams' : 'ClusteringPreprocessingParams.ReductionParams',
    'com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams$OutliersParams' : 'ClusteringPreprocessingParams.OutliersParams',
    'com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams' : 'ClusteringPreprocessingParams',
    'com.dataiku.dip.analysis.model.preprocessing.ClusteringPreprocessingParams$OutliersHandlingMethod' : 'ClusteringPreprocessingParams.OutliersHandlingMethod',
    'com.dataiku.dip.analysis.model.prediction.assertions.MLAssertionsParams$MLAssertion$AssertionCondition' : 'MLAssertionsParams.MLAssertion.AssertionCondition',
    'com.dataiku.dip.analysis.model.prediction.assertions.MLAssertionsParams' : 'MLAssertionsParams',
    'com.dataiku.dip.analysis.model.prediction.assertions.MLAssertionsParams$MLAssertion' : 'MLAssertionsParams.MLAssertion',
    'com.dataiku.dip.eda.compute.distributions.Laplace$FittedLaplace' : 'Laplace.FittedLaplace',
    'com.dataiku.dip.eda.compute.distributions.Laplace' : 'Laplace',
    'com.dataiku.dip.eda.worksheets.cards.MultivariateCard' : 'MultivariateCard',
    'com.dataiku.dip.analysis.model.prediction.TimeseriesForecastingModelIntrinsicPerf' : 'TimeseriesForecastingModelIntrinsicPerf',
    'com.dataiku.dip.experimenttracking.ExperimentTag' : 'ExperimentTag',
    'com.dataiku.dip.partitioning.ExactValueDimension' : 'ExactValueDimension',
    'com.dataiku.dip.workspaces.Workspace$WorkspaceObject' : 'Workspace.WorkspaceObject',
    'com.dataiku.dip.workspaces.Workspace$HtmlLink' : 'Workspace.HtmlLink',
    'com.dataiku.dip.workspaces.Workspace' : 'Workspace',
    'com.dataiku.dip.workspaces.Workspace$Permissions' : 'Workspace.Permissions',
    'com.dataiku.dip.workspaces.Workspace$PermissionItem' : 'Workspace.PermissionItem',
    'com.dataiku.dip.pivot.backend.dss.LongDataList' : 'LongDataList',
    'com.dataiku.dip.analysis.model.MLTask$DiagnosticsSettings' : 'MLTask.DiagnosticsSettings',
    'com.dataiku.dip.analysis.model.MLTask$DiagnosticSetting' : 'MLTask.DiagnosticSetting',
    'com.dataiku.dip.analysis.model.MLTask$PredictedDataChart' : 'MLTask.PredictedDataChart',
    'com.dataiku.dip.analysis.model.MLTask$MLTaskType' : 'MLTask.MLTaskType',
    'com.dataiku.dip.analysis.model.MLTask$BackendType' : 'MLTask.BackendType',
    'com.dataiku.dip.analysis.model.MLTask' : 'MLTask',
    'com.dataiku.dip.eda.compute.computations.univariate.PairwiseMoodTest' : 'PairwiseMoodTest',
    'com.dataiku.dip.eda.compute.computations.univariate.PairwiseMoodTest$PairwiseMoodTestResult' : 'PairwiseMoodTest.PairwiseMoodTestResult',
    'com.dataiku.dip.pivot.backend.model.maps.PTMapAggrAdminRequest' : 'PTMapAggrAdminRequest',
    'com.dataiku.dip.pivot.backend.dss.DoubleDataTensor' : 'DoubleDataTensor',
    'com.dataiku.dip.analysis.model.ModelDetailsBase' : 'ModelDetailsBase',
    'com.dataiku.dip.analysis.model.core.BaseUserMeta' : 'BaseUserMeta',
    'com.dataiku.dip.analysis.model.prediction.ResolvedClassicalPredictionCoreParams' : 'ResolvedClassicalPredictionCoreParams',
    'com.dataiku.dip.experimenttracking.RunData' : 'RunData',
    'com.dataiku.dip.analysis.model.clustering.ResolvedClusteringCoreParams' : 'ResolvedClusteringCoreParams',
    'com.dataiku.dip.eda.compute.computations.univariate.Mean' : 'Mean',
    'com.dataiku.dip.eda.compute.computations.univariate.Mean$MeanResult' : 'Mean.MeanResult',
    'com.dataiku.dip.analysis.model.preprocessing.ClassicalPredictionPreprocessingParams' : 'ClassicalPredictionPreprocessingParams',
    'com.dataiku.dip.eda.compute.computations.bivariate.Spearman$SpearmanResult' : 'Spearman.SpearmanResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.Spearman' : 'Spearman',
    'com.dataiku.dip.pivot.backend.model.maps.PTMapScatterResponse' : 'PTMapScatterResponse',
    'com.dataiku.dip.eda.compute.distributions.JointNormal' : 'JointNormal',
    'com.dataiku.dip.eda.compute.distributions.JointNormal$FittedJointNormal' : 'JointNormal.FittedJointNormal',
    'com.dataiku.dip.pivot.backend.model.maps.PTMapRawGeometryRequest' : 'PTMapRawGeometryRequest',
    'com.dataiku.dip.eda.compute.computations.FailedResult' : 'FailedResult',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA$UnitRootTestZAResult' : 'UnitRootTestZA.UnitRootTestZAResult',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA$RegressionMode' : 'UnitRootTestZA.RegressionMode',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA' : 'UnitRootTestZA',
    'com.dataiku.dip.eda.compute.filtering.AnumFilter' : 'AnumFilter',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS$RegressionMode' : 'UnitRootTestKPSS.RegressionMode',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS' : 'UnitRootTestKPSS',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS$UnitRootTestKPSSResult' : 'UnitRootTestKPSS.UnitRootTestKPSSResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Variance' : 'Variance',
    'com.dataiku.dip.eda.compute.computations.univariate.Variance$VarianceResult' : 'Variance.VarianceResult',
    'com.dataiku.dip.server.controllers.ProjectsCRUDController$ProjectHead' : 'ProjectsCRUDController.ProjectHead',
    'com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$FitCurveResult' : 'FitCurve.FitCurveResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$ScoreData' : 'FitCurve.ScoreData',
    'com.dataiku.dip.eda.compute.computations.bivariate.FitCurve$PlotData' : 'FitCurve.PlotData',
    'com.dataiku.dip.eda.compute.computations.bivariate.FitCurve' : 'FitCurve',
    'com.dataiku.dip.analysis.model.prediction.ResolvedCausalPredictionCoreParams' : 'ResolvedCausalPredictionCoreParams',
    'com.dataiku.dip.pivot.backend.model.maps.PTMapAggrResponse' : 'PTMapAggrResponse',
    'com.dataiku.dip.analysis.model.prediction.CalibrationParams$CalibrationMethod' : 'CalibrationParams.CalibrationMethod',
    'com.dataiku.dip.analysis.model.prediction.CalibrationParams' : 'CalibrationParams',
    'com.dataiku.dip.analysis.model.preprocessing.PredictionPreprocessingParams$MappingValue' : 'PredictionPreprocessingParams.MappingValue',
    'com.dataiku.dip.analysis.model.preprocessing.PredictionPreprocessingParams' : 'PredictionPreprocessingParams',
    'com.dataiku.dip.server.services.DatasetNotebookService$NotebookType' : 'DatasetNotebookService.NotebookType',
    'com.dataiku.dip.server.services.DatasetNotebookService$SimpleNotebook' : 'DatasetNotebookService.SimpleNotebook',
    'com.dataiku.dip.eda.compute.computations.common.DummyComputation$DummyComputationResult' : 'DummyComputation.DummyComputationResult',
    'com.dataiku.dip.eda.compute.computations.common.DummyComputation' : 'DummyComputation',
    'com.dataiku.dip.eda.worksheets.cards.DurbinWatsonCard' : 'DurbinWatsonCard',
    'com.dataiku.dip.eda.worksheets.cards.DurbinWatsonCard$DurbinWatsonCardResult' : 'DurbinWatsonCard.DurbinWatsonCardResult',
    'com.dataiku.dip.coremodel.Checklist' : 'Checklist',
    'com.dataiku.dip.coremodel.Checklist$ChecklistItem' : 'Checklist.ChecklistItem',
    'com.dataiku.dip.eda.EdaErrorCodes' : 'EdaErrorCodes',
    'com.dataiku.dip.analysis.model.prediction.DataEvaluationMetrics' : 'DataEvaluationMetrics',
    'com.dataiku.dip.eda.compute.computations.univariate.ZTest1Samp$ZTest1SampResult' : 'ZTest1Samp.ZTest1SampResult',
    'com.dataiku.dip.eda.compute.computations.univariate.ZTest1Samp' : 'ZTest1Samp',
    'com.dataiku.dip.analysis.model.prediction.PartitionedModelParams' : 'PartitionedModelParams',
    'com.dataiku.dip.pivot.backend.model.maps.PTMapScatterRequest' : 'PTMapScatterRequest',
    'com.dataiku.dip.dao.SavedModel$ModelPublishPolicy' : 'SavedModel.ModelPublishPolicy',
    'com.dataiku.dip.dao.SavedModel$SavedModelType' : 'SavedModel.SavedModelType',
    'com.dataiku.dip.dao.SavedModel' : 'SavedModel',
    'com.dataiku.dip.dao.SavedModel$SavedModelListItem' : 'SavedModel.SavedModelListItem',
    'com.dataiku.dip.pivot.backend.model.maps.PivotTableWebappResponse' : 'PivotTableWebappResponse',
    'com.dataiku.dip.labeling.LabelingRecord' : 'LabelingRecord',
    'com.dataiku.dip.shaker.model.ScriptStep$MetaType' : 'ScriptStep.MetaType',
    'com.dataiku.dip.shaker.model.ScriptStep' : 'ScriptStep',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard' : 'AbstractNSampTestCard',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$AbstractNSampleTestCardResult' : 'AbstractNSampTestCard.AbstractNSampleTestCardResult',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractNSampTestCard$GroupInfo' : 'AbstractNSampTestCard.GroupInfo',
    'com.dataiku.dip.analysis.model.clustering.ClusteringModelDetails' : 'ClusteringModelDetails',
    'com.dataiku.dip.labeling.region.LabelingRegion' : 'LabelingRegion',
    'com.dataiku.dip.analysis.model.prediction.ResolvedPredictionCoreParams' : 'ResolvedPredictionCoreParams',
    'com.dataiku.dip.eda.compute.computations.univariate.Min$MinResult' : 'Min.MinResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Min' : 'Min',
    'com.dataiku.dip.eda.compute.computations.univariate.StdDev' : 'StdDev',
    'com.dataiku.dip.eda.compute.computations.univariate.StdDev$StdDevResult' : 'StdDev.StdDevResult',
    'com.dataiku.dip.experimenttracking.RunStatus' : 'RunStatus',
    'com.dataiku.dip.analysis.model.preprocessing.CausalPredictionPreprocessingParams' : 'CausalPredictionPreprocessingParams',
    'com.dataiku.dip.pivot.frontend.model.DimensionDef' : 'DimensionDef',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard$AbstractHeaderCardResult' : 'AbstractHeaderCard.AbstractHeaderCardResult',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractHeaderCard' : 'AbstractHeaderCard',
    'com.dataiku.dip.eda.compute.distributions.Triangular$FittedTriangular' : 'Triangular.FittedTriangular',
    'com.dataiku.dip.eda.compute.distributions.Triangular' : 'Triangular',
    'com.dataiku.dip.eda.worksheets.cards.fragments.BoxPlotFragment' : 'BoxPlotFragment',
    'com.dataiku.dip.analysis.model.CausalPredictionModelDetails' : 'CausalPredictionModelDetails',
    'com.dataiku.dip.server.controllers.JupyterController$JupyterNotebookListItem' : 'JupyterController.JupyterNotebookListItem',
    'com.dataiku.dip.dao.InterestsInternalDB$Interest' : 'InterestsInternalDB.Interest',
    'com.dataiku.dip.dao.InterestsInternalDB$Watching' : 'InterestsInternalDB.Watching',
    'com.dataiku.dip.eda.worksheets.cards.PlaygroundCard' : 'PlaygroundCard',
    'com.dataiku.dip.eda.worksheets.cards.PlaygroundCard$PlaygroundCardResult' : 'PlaygroundCard.PlaygroundCardResult',
    'com.dataiku.dip.analysis.ml.prediction.flow.PredictionSMMgmtService$PredictionSMStatus' : 'PredictionSMMgmtService.PredictionSMStatus',
    'com.dataiku.dip.analysis.model.preprocessing.VectorFeaturePreprocessingParams$VectorHandlingMethod' : 'VectorFeaturePreprocessingParams.VectorHandlingMethod',
    'com.dataiku.dip.analysis.model.preprocessing.VectorFeaturePreprocessingParams$MissingHandlingMethod' : 'VectorFeaturePreprocessingParams.MissingHandlingMethod',
    'com.dataiku.dip.analysis.model.preprocessing.VectorFeaturePreprocessingParams' : 'VectorFeaturePreprocessingParams',
    'com.dataiku.dip.analysis.model.preprocessing.VectorFeaturePreprocessingParams$VectorImputationMethod' : 'VectorFeaturePreprocessingParams.VectorImputationMethod',
    'com.dataiku.dip.eda.worksheets.models.Variable' : 'Variable',
    'com.dataiku.dip.eda.worksheets.models.Variable$Type' : 'Variable.Type',
    'com.dataiku.dip.analysis.model.prediction.IntegerHyperparameterDimension' : 'IntegerHyperparameterDimension',
    'com.dataiku.dip.analysis.model.prediction.ResolvedClassicalPredictionPreprocessingParams' : 'ResolvedClassicalPredictionPreprocessingParams',
    'com.dataiku.dip.eda.compute.computations.timeseries.DurbinWatson' : 'DurbinWatson',
    'com.dataiku.dip.eda.compute.computations.timeseries.DurbinWatson$DurbinWatsonResult' : 'DurbinWatson.DurbinWatsonResult',
    'com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams$BinarizeThresholdMode' : 'NumFeaturePreprocessingParams.BinarizeThresholdMode',
    'com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams$NumericalHandlingMethod' : 'NumFeaturePreprocessingParams.NumericalHandlingMethod',
    'com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams$RescalingMethod' : 'NumFeaturePreprocessingParams.RescalingMethod',
    'com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams$NumericalImputationMethod' : 'NumFeaturePreprocessingParams.NumericalImputationMethod',
    'com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams' : 'NumFeaturePreprocessingParams',
    'com.dataiku.dip.analysis.model.preprocessing.NumFeaturePreprocessingParams$MissingHandlingMethod' : 'NumFeaturePreprocessingParams.MissingHandlingMethod',
    'com.dataiku.dip.analysis.model.prediction.FloatHyperparameterDimension' : 'FloatHyperparameterDimension',
    'com.dataiku.dip.pivot.frontend.model.ComputeMode' : 'ComputeMode',
    'com.dataiku.dip.coremodel.RecipeEnginesPreferenceConfig' : 'RecipeEnginesPreferenceConfig',
    'com.dataiku.dip.eda.compute.computations.univariate.Sum$SumResult' : 'Sum.SumResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Sum' : 'Sum',
    'com.dataiku.dip.eda.compute.computations.AvailableResult' : 'AvailableResult',
    'com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard' : 'ParallelCoordinatesPlotCard',
    'com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard$ParallelCoordinatesPlotCardResult' : 'ParallelCoordinatesPlotCard.ParallelCoordinatesPlotCardResult',
    'com.dataiku.dip.eda.worksheets.cards.ParallelCoordinatesPlotCard$ParallelCoordinates' : 'ParallelCoordinatesPlotCard.ParallelCoordinates',
    'com.dataiku.dip.server.services.AccessibleObjectsService$AccessibleObject' : 'AccessibleObjectsService.AccessibleObject',
    'com.dataiku.dip.eda.worksheets.models.SplitBySpec' : 'SplitBySpec',
    'com.dataiku.dip.analysis.model.clustering.ClustersFacts' : 'ClustersFacts',
    'com.dataiku.dip.analysis.model.clustering.ClustersFacts$NumericalClusterFact' : 'ClustersFacts.NumericalClusterFact',
    'com.dataiku.dip.analysis.model.clustering.ClustersFacts$ClusterFact' : 'ClustersFacts.ClusterFact',
    'com.dataiku.dip.analysis.model.clustering.ClustersFacts$ClusterFacts' : 'ClustersFacts.ClusterFacts',
    'com.dataiku.dip.analysis.model.clustering.ClustersFacts$CategoricalClusterFact' : 'ClustersFacts.CategoricalClusterFact',
    'com.dataiku.dip.eda.compute.computations.bivariate.KendallTau$KendallTauResult' : 'KendallTau.KendallTauResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.KendallTau' : 'KendallTau',
    'com.dataiku.dip.pivot.backend.model.NumericalAxisParams' : 'NumericalAxisParams',
    'com.dataiku.dip.pivot.backend.model.NumericalAxisParams$BinningMode' : 'NumericalAxisParams.BinningMode',
    'com.dataiku.dip.pivot.backend.model.NumericalAxisParams$EmptyBinsMode' : 'NumericalAxisParams.EmptyBinsMode',
    'com.dataiku.dip.eda.worksheets.cards.common.GroupedCompiledCardWrapper$GroupedCardResult' : 'GroupedCompiledCardWrapper.GroupedCardResult',
    'com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard' : 'Abstract2SampTestCard',
    'com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard$Abstract2SampTestCardResult' : 'Abstract2SampTestCard.Abstract2SampTestCardResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.MutualInformation' : 'MutualInformation',
    'com.dataiku.dip.eda.compute.computations.bivariate.MutualInformation$MutualInformationResult' : 'MutualInformation.MutualInformationResult',
    'com.dataiku.dip.analysis.model.prediction.ResolvedDeepHubPredictionCoreParams' : 'ResolvedDeepHubPredictionCoreParams',
    'com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$FeatureType' : 'FeaturePreprocessingParams.FeatureType',
    'com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams' : 'FeaturePreprocessingParams',
    'com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$FeatureHandlingReason' : 'FeaturePreprocessingParams.FeatureHandlingReason',
    'com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$Role' : 'FeaturePreprocessingParams.Role',
    'com.dataiku.dip.analysis.model.preprocessing.FeaturePreprocessingParams$FeatureState' : 'FeaturePreprocessingParams.FeatureState',
    'com.dataiku.dip.labeling.classification.ClassificationAnnotation' : 'ClassificationAnnotation',
    'com.dataiku.dip.eda.compute.computations.univariate.Sem' : 'Sem',
    'com.dataiku.dip.eda.compute.computations.univariate.Sem$SemResult' : 'Sem.SemResult',
    'com.dataiku.dip.pivot.frontend.model.DisplayAxis' : 'DisplayAxis',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard$CategoricalHistogramCardResult' : 'CategoricalHistogramCard.CategoricalHistogramCardResult',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard$HistogramData' : 'CategoricalHistogramCard.HistogramData',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.CategoricalHistogramCard' : 'CategoricalHistogramCard',
    'com.dataiku.dip.shaker.server.SampleMetadata' : 'SampleMetadata',
    'com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams' : 'DeepHubMetricParams',
    'com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams$EvaluationMetric' : 'DeepHubMetricParams.EvaluationMetric',
    'com.dataiku.dip.analysis.model.prediction.DeepHubMetricParams$ConfidenceScoreThresholdOptimizationMetric' : 'DeepHubMetricParams.ConfidenceScoreThresholdOptimizationMetric',
    'com.dataiku.dip.pivot.backend.model.PivotTableTensorResponse' : 'PivotTableTensorResponse',
    'com.dataiku.dip.eda.compute.distributions.Binomial$FittedBinomial' : 'Binomial.FittedBinomial',
    'com.dataiku.dip.eda.compute.distributions.Binomial' : 'Binomial',
    'com.dataiku.dip.eda.worksheets.cards.SignTest1SampCard$SignTest1SampCardResult' : 'SignTest1SampCard.SignTest1SampCardResult',
    'com.dataiku.dip.eda.worksheets.cards.SignTest1SampCard' : 'SignTest1SampCard',
    'com.dataiku.dip.eda.worksheets.cards.PCACard' : 'PCACard',
    'com.dataiku.dip.eda.worksheets.cards.PCACard$PCACardResult' : 'PCACard.PCACardResult',
    'com.dataiku.dip.shaker.text.StringNormalizationMode' : 'StringNormalizationMode',
    'com.dataiku.dip.pivot.backend.model.maps.PivotTableFiltersResponse' : 'PivotTableFiltersResponse',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard' : 'ScatterPlotCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard$ScatterPlotSeries' : 'ScatterPlotCard.ScatterPlotSeries',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.ScatterPlotCard$ScatterPlotCardResult' : 'ScatterPlotCard.ScatterPlotCardResult',
    'com.dataiku.dip.pivot.backend.model.density.PT2DDensityResponse' : 'PT2DDensityResponse',
    'com.dataiku.dip.analysis.model.prediction.OddIntegerHyperparameterDimension' : 'OddIntegerHyperparameterDimension',
    'com.dataiku.dip.eda.worksheets.cards.MannKendallTestCard' : 'MannKendallTestCard',
    'com.dataiku.dip.eda.worksheets.cards.MannKendallTestCard$MannKendallTestCardResult' : 'MannKendallTestCard.MannKendallTestCardResult',
    'com.dataiku.dip.labeling.VerifiedLabel' : 'VerifiedLabel',
    'com.dataiku.dip.labeling.VerifiedLabel$ClassificationVerifiedLabel' : 'VerifiedLabel.ClassificationVerifiedLabel',
    'com.dataiku.dip.labeling.VerifiedLabel$ObjectDetectionVerifiedLabel$BoundingBoxPair' : 'VerifiedLabel.ObjectDetectionVerifiedLabel.BoundingBoxPair',
    'com.dataiku.dip.labeling.VerifiedLabel$ObjectDetectionVerifiedLabel' : 'VerifiedLabel.ObjectDetectionVerifiedLabel',
    'com.dataiku.dip.eda.worksheets.cards.common.HeatmapParams' : 'HeatmapParams',
    'com.dataiku.dip.pivot.frontend.model.NumberFormattingOptions' : 'NumberFormattingOptions',
    'com.dataiku.dip.coremodel.AppHomepageTile$RunInlinePythonTile' : 'AppHomepageTile.RunInlinePythonTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$EditFolderTile' : 'AppHomepageTile.EditFolderTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$DownloadReportTile' : 'AppHomepageTile.DownloadReportTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$PropagateSchemaTileBehavior' : 'AppHomepageTile.PropagateSchemaTileBehavior',
    'com.dataiku.dip.coremodel.AppHomepageTile$DashboardLinkTile' : 'AppHomepageTile.DashboardLinkTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$EditAnyDatasetTile' : 'AppHomepageTile.EditAnyDatasetTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$GuessTrainDeployTile' : 'AppHomepageTile.GuessTrainDeployTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$AppHomepageTileType' : 'AppHomepageTile.AppHomepageTileType',
    'com.dataiku.dip.coremodel.AppHomepageTile$DownloadManagedFolderTile' : 'AppHomepageTile.DownloadManagedFolderTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$EditFSDatasetTile' : 'AppHomepageTile.EditFSDatasetTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$UploadDatasetSetFileTile' : 'AppHomepageTile.UploadDatasetSetFileTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$ManagedFolderSetFileTileBehavior' : 'AppHomepageTile.ManagedFolderSetFileTileBehavior',
    'com.dataiku.dip.coremodel.AppHomepageTile$PartitionDef' : 'AppHomepageTile.PartitionDef',
    'com.dataiku.dip.coremodel.AppHomepageTile$ManagedFolderSetFileTile' : 'AppHomepageTile.ManagedFolderSetFileTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$DownloadDashboardTile' : 'AppHomepageTile.DownloadDashboardTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$EditFSDatasetTileBehavior' : 'AppHomepageTile.EditFSDatasetTileBehavior',
    'com.dataiku.dip.coremodel.AppHomepageTile$DownloadDatasetTile' : 'AppHomepageTile.DownloadDatasetTile',
    'com.dataiku.dip.coremodel.AppHomepageTile' : 'AppHomepageTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$VariableDisplayTile' : 'AppHomepageTile.VariableDisplayTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$RunScenarioTile' : 'AppHomepageTile.RunScenarioTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$EditSQLDatasetTile' : 'AppHomepageTile.EditSQLDatasetTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$UploadDatasetSetFileTileBehavior' : 'AppHomepageTile.UploadDatasetSetFileTileBehavior',
    'com.dataiku.dip.coremodel.AppHomepageTile$PropagateSchemaTile' : 'AppHomepageTile.PropagateSchemaTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$EditAnyStreamingEndpointTile' : 'AppHomepageTile.EditAnyStreamingEndpointTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$EditInlineDatasetTile' : 'AppHomepageTile.EditInlineDatasetTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$ProjectVariablesTile' : 'AppHomepageTile.ProjectVariablesTile',
    'com.dataiku.dip.coremodel.AppHomepageTile$EditFolderTileBehavior' : 'AppHomepageTile.EditFolderTileBehavior',
    'com.dataiku.dip.coremodel.AppHomepageTile$EditSQLDatasetTileBehavior' : 'AppHomepageTile.EditSQLDatasetTileBehavior',
    'com.dataiku.dip.coremodel.AppHomepageTile$ProjectVariablesTileBehavior' : 'AppHomepageTile.ProjectVariablesTileBehavior',
    'com.dataiku.dip.coremodel.AppHomepageTile$ManagedFolderLinkTile' : 'AppHomepageTile.ManagedFolderLinkTile',
    'com.dataiku.dip.eda.compute.grouping.GroupingResult' : 'GroupingResult',
    'com.dataiku.dip.analysis.model.prediction.CausalPredictionModelPerf' : 'CausalPredictionModelPerf',
    'com.dataiku.dip.analysis.model.prediction.CausalPredictionModelPerf$CausalPerf' : 'CausalPredictionModelPerf.CausalPerf',
    'com.dataiku.dip.analysis.model.prediction.CausalPredictionModelPerf$CausalPerf$GainCurvePoint' : 'CausalPredictionModelPerf.CausalPerf.GainCurvePoint',
    'com.dataiku.dip.analysis.model.prediction.CausalPredictionModelPerf$CausalPerf$ThresholdIndependentMetrics' : 'CausalPredictionModelPerf.CausalPerf.ThresholdIndependentMetrics',
    'com.dataiku.dip.eda.compute.filtering.NotFilter' : 'NotFilter',
    'com.dataiku.dip.shaker.facet.AlphanumFacetWithMap' : 'AlphanumFacetWithMap',
    'com.dataiku.dip.analysis.model.clustering.ClusteringMLTask$ClusterSampling' : 'ClusteringMLTask.ClusterSampling',
    'com.dataiku.dip.analysis.model.clustering.ClusteringMLTask' : 'ClusteringMLTask',
    'com.dataiku.dip.pivot.backend.model.scatter.PTScatterRequest' : 'PTScatterRequest',
    'com.dataiku.dip.eda.compute.grouping.TopNTimeGrouping' : 'TopNTimeGrouping',
    'com.dataiku.dip.eda.compute.grouping.TopNTimeGrouping$TopNTimeGroupingResult' : 'TopNTimeGrouping.TopNTimeGroupingResult',
    'com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard' : 'Fit2DDistributionCard',
    'com.dataiku.dip.eda.worksheets.cards.Fit2DDistributionCard$Fit2DDistributionCardResult' : 'Fit2DDistributionCard.Fit2DDistributionCardResult',
    'com.dataiku.dip.shaker.server.DataService$ShakerRecipeSchemaColumn' : 'DataService.ShakerRecipeSchemaColumn',
    'com.dataiku.dip.shaker.server.DataService$ShakerRecipeSchema' : 'DataService.ShakerRecipeSchema',
    'com.dataiku.dip.eda.worksheets.models.Worksheet' : 'Worksheet',
    'com.dataiku.dip.eda.compute.grouping.BinnedGrouping$BinningMode' : 'BinnedGrouping.BinningMode',
    'com.dataiku.dip.eda.compute.grouping.BinnedGrouping$BinnedGroupingResult' : 'BinnedGrouping.BinnedGroupingResult',
    'com.dataiku.dip.eda.compute.grouping.BinnedGrouping' : 'BinnedGrouping',
    'com.dataiku.dip.eda.worksheets.cards.ACFPlotCard' : 'ACFPlotCard',
    'com.dataiku.dip.eda.worksheets.cards.ACFPlotCard$ACFPlotCardResult' : 'ACFPlotCard.ACFPlotCardResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.Pearson' : 'Pearson',
    'com.dataiku.dip.eda.compute.computations.bivariate.Pearson$PearsonResult' : 'Pearson.PearsonResult',
    'com.dataiku.dip.coremodel.SerializedProject$ReaderAuthorization$Mode' : 'SerializedProject.ReaderAuthorization.Mode',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectExposedObjects' : 'SerializedProject.ProjectExposedObjects',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectRelocationSettings' : 'SerializedProject.ProjectRelocationSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectWikiSettings' : 'SerializedProject.ProjectWikiSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectSettings' : 'SerializedProject.ProjectSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectSparkEngineCreationSettings' : 'SerializedProject.ProjectSparkEngineCreationSettings',
    'com.dataiku.dip.coremodel.SerializedProject$LimitsSettings' : 'SerializedProject.LimitsSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectType' : 'SerializedProject.ProjectType',
    'com.dataiku.dip.coremodel.SerializedProject' : 'SerializedProject',
    'com.dataiku.dip.coremodel.SerializedProject$PermissionsVersion' : 'SerializedProject.PermissionsVersion',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectHiveEngineCreationSettings' : 'SerializedProject.ProjectHiveEngineCreationSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectIntegration' : 'SerializedProject.ProjectIntegration',
    'com.dataiku.dip.coremodel.SerializedProject$PermissionItem' : 'SerializedProject.PermissionItem',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectDashboardAuthorizations' : 'SerializedProject.ProjectDashboardAuthorizations',
    'com.dataiku.dip.coremodel.SerializedProject$FlowDisplaySettings$ZonesGraphRenderingAlgorithm' : 'SerializedProject.FlowDisplaySettings.ZonesGraphRenderingAlgorithm',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectAppType' : 'SerializedProject.ProjectAppType',
    'com.dataiku.dip.coremodel.SerializedProject$IntegrationsSettings' : 'SerializedProject.IntegrationsSettings',
    'com.dataiku.dip.coremodel.SerializedProject$SQLLikeRecipeInitializationMode' : 'SerializedProject.SQLLikeRecipeInitializationMode',
    'com.dataiku.dip.coremodel.SerializedProject$ReaderAuthorization' : 'SerializedProject.ReaderAuthorization',
    'com.dataiku.dip.coremodel.SerializedProject$FlowBuildSettings' : 'SerializedProject.FlowBuildSettings',
    'com.dataiku.dip.coremodel.SerializedProject$ProjectImpalaEngineCreationSettings' : 'SerializedProject.ProjectImpalaEngineCreationSettings',
    'com.dataiku.dip.coremodel.SerializedProject$AdditionalDashboardUsers' : 'SerializedProject.AdditionalDashboardUsers',
    'com.dataiku.dip.coremodel.SerializedProject$AdditionalDashboardUser' : 'SerializedProject.AdditionalDashboardUser',
    'com.dataiku.dip.coremodel.SerializedProject$FlowDisplaySettings' : 'SerializedProject.FlowDisplaySettings',
    'com.dataiku.dip.coremodel.ConditionalOutput$Rule' : 'ConditionalOutput.Rule',
    'com.dataiku.dip.coremodel.ConditionalOutput' : 'ConditionalOutput',
    'com.dataiku.dip.coremodel.ConditionalOutput$ComparisonOperation' : 'ConditionalOutput.ComparisonOperation',
    'com.dataiku.dip.eda.worksheets.cards.Card' : 'Card',
    'com.dataiku.dip.eda.compute.grouping.UnionGrouping' : 'UnionGrouping',
    'com.dataiku.dip.eda.compute.grouping.UnionGrouping$UnionGroupingResult' : 'UnionGrouping.UnionGroupingResult',
    'com.dataiku.dip.projects.importexport.model.ProjectRemappingSettings' : 'ProjectRemappingSettings',
    'com.dataiku.dip.projects.importexport.model.ProjectRemappingSettings$ConnectionRemapping' : 'ProjectRemappingSettings.ConnectionRemapping',
    'com.dataiku.dip.projects.importexport.model.ProjectRemappingSettings$CodeEnvRemapping' : 'ProjectRemappingSettings.CodeEnvRemapping',
    'com.dataiku.dip.eda.worksheets.queries.InteractiveQuery$InteractiveQueryResult' : 'InteractiveQuery.InteractiveQueryResult',
    'com.dataiku.dip.eda.worksheets.queries.InteractiveQuery' : 'InteractiveQuery',
    'com.dataiku.dip.analysis.ml.prediction.guess.PredictionGuessPolicy' : 'PredictionGuessPolicy',
    'com.dataiku.dip.pivot.backend.model.PivotTableResponse' : 'PivotTableResponse',
    'com.dataiku.dip.pivot.backend.model.PivotTableResponse$PivotEngine' : 'PivotTableResponse.PivotEngine',
    'com.dataiku.dip.code.PythonCodeEnvPackagesUtils$ExperimentTrackingCompatibilityInfo' : 'PythonCodeEnvPackagesUtils.ExperimentTrackingCompatibilityInfo',
    'com.dataiku.dip.code.PythonCodeEnvPackagesUtils$CodeEnvExperimentTrackingCompat' : 'PythonCodeEnvPackagesUtils.CodeEnvExperimentTrackingCompat',
    'com.dataiku.dip.code.PythonCodeEnvPackagesUtils$ExperimentTrackingCompatibilityInfo$CompatibilityStatus' : 'PythonCodeEnvPackagesUtils.ExperimentTrackingCompatibilityInfo.CompatibilityStatus',
    'com.dataiku.dip.eda.compute.sampling.Sample' : 'Sample',
    'com.dataiku.dip.eda.compute.grouping.MergeGrouping$MergeGroupingResult' : 'MergeGrouping.MergeGroupingResult',
    'com.dataiku.dip.eda.compute.grouping.MergeGrouping' : 'MergeGrouping',
    'com.dataiku.dip.analysis.ml.clustering.flow.ClusteringSMMgmtService$ClusteringSMStatus' : 'ClusteringSMMgmtService.ClusteringSMStatus',
    'com.dataiku.dip.dashboards.model.DashboardPage' : 'DashboardPage',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractPairwiseTestCard' : 'AbstractPairwiseTestCard',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateFrequencyTableCard' : 'UnivariateFrequencyTableCard',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.UnivariateFrequencyTableCard$UnivariateFrequencyTableCardResult' : 'UnivariateFrequencyTableCard.UnivariateFrequencyTableCardResult',
    'com.dataiku.dip.pivot.backend.model.Aggregation$ComputeMode' : 'Aggregation.ComputeMode',
    'com.dataiku.dip.pivot.backend.model.Aggregation' : 'Aggregation',
    'com.dataiku.dip.pivot.backend.model.Aggregation$Function' : 'Aggregation.Function',
    'com.dataiku.dip.analysis.ml.MLSparkParams' : 'MLSparkParams',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHeaderCard$BivariateHeaderCardResult' : 'BivariateHeaderCard.BivariateHeaderCardResult',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHeaderCard' : 'BivariateHeaderCard',
    'com.dataiku.dip.eda.compute.sampling.DataSpec' : 'DataSpec',
    'com.dataiku.dip.eda.worksheets.cards.fragments.CountCompleteFragment' : 'CountCompleteFragment',
    'com.dataiku.dip.labeling.Label$NamedEntityExtractionLabel' : 'Label.NamedEntityExtractionLabel',
    'com.dataiku.dip.labeling.Label$ImageClassificationLabel' : 'Label.ImageClassificationLabel',
    'com.dataiku.dip.labeling.Label' : 'Label',
    'com.dataiku.dip.labeling.Label$ObjectDetectionLabel' : 'Label.ObjectDetectionLabel',
    'com.dataiku.dip.coremodel.VersionTag' : 'VersionTag',
    'com.dataiku.dip.coremodel.VersionTag$VersionTagUser' : 'VersionTag.VersionTagUser',
    'com.dataiku.dip.eda.compute.grouping.SubsetGrouping' : 'SubsetGrouping',
    'com.dataiku.dip.eda.compute.grouping.SubsetGrouping$SubsetGroupingResult' : 'SubsetGrouping.SubsetGroupingResult',
    'com.dataiku.dip.eda.worksheets.WorksheetsService$WorksheetHead' : 'WorksheetsService.WorksheetHead',
    'com.dataiku.dip.eda.compute.computations.univariate.Quantiles' : 'Quantiles',
    'com.dataiku.dip.eda.compute.computations.univariate.Quantiles$QuantileDesc' : 'Quantiles.QuantileDesc',
    'com.dataiku.dip.eda.compute.computations.univariate.Quantiles$QuantilesResult' : 'Quantiles.QuantilesResult',
    'com.dataiku.dip.labeling.LabelingRecordToAnnotate' : 'LabelingRecordToAnnotate',
    'com.dataiku.dip.eda.worksheets.cards.Chi2IndTestCard' : 'Chi2IndTestCard',
    'com.dataiku.dip.eda.worksheets.cards.Chi2IndTestCard$Chi2IndTestCardResult' : 'Chi2IndTestCard.Chi2IndTestCardResult',
    'com.dataiku.dip.analysis.model.prediction.MulticlassModelPerf$MulticlassActualClassConfusion' : 'MulticlassModelPerf.MulticlassActualClassConfusion',
    'com.dataiku.dip.analysis.model.prediction.MulticlassModelPerf$MulticlassConfusion' : 'MulticlassModelPerf.MulticlassConfusion',
    'com.dataiku.dip.eda.compute.computations.univariate.Kurtosis$KurtosisResult' : 'Kurtosis.KurtosisResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Kurtosis' : 'Kurtosis',
    'com.dataiku.dip.analysis.model.prediction.TimestepParams' : 'TimestepParams',
    'com.dataiku.dip.analysis.model.prediction.TimestepParams$Timeunit' : 'TimestepParams.Timeunit',
    'com.dataiku.dip.eda.worksheets.cards.fragments.NumericalHistogramFragment' : 'NumericalHistogramFragment',
    'com.dataiku.dip.datasets.StreamableDatasetSelection' : 'StreamableDatasetSelection',
    'com.dataiku.dip.eda.compute.computations.univariate.Entropy$EntropyResult' : 'Entropy.EntropyResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Entropy' : 'Entropy',
    'com.dataiku.dip.eda.worksheets.models.BinningMode' : 'BinningMode',
    'com.dataiku.dip.eda.compute.computations.common.Count' : 'Count',
    'com.dataiku.dip.eda.compute.computations.common.Count$CountResult' : 'Count.CountResult',
    'com.dataiku.dip.dashboards.model.TilesGrid' : 'TilesGrid',
    'com.dataiku.dip.coremodel.HeadWithVersioningInfo' : 'HeadWithVersioningInfo',
    'com.dataiku.dip.eda.compute.computations.univariate.MaxTime' : 'MaxTime',
    'com.dataiku.dip.eda.compute.computations.univariate.MaxTime$MaxTimeResult' : 'MaxTime.MaxTimeResult',
    'com.dataiku.dip.shaker.server.TableColoringService' : 'TableColoringService',
    'com.dataiku.dip.eda.compute.computations.timeseries.ACF' : 'ACF',
    'com.dataiku.dip.eda.compute.computations.timeseries.ACF$ACFResult' : 'ACF.ACFResult',
    'com.dataiku.dip.eda.compute.stats.PValueAdjustmentMethod' : 'PValueAdjustmentMethod',
    'com.dataiku.dip.eda.compute.computations.univariate.Max' : 'Max',
    'com.dataiku.dip.eda.compute.computations.univariate.Max$MaxResult' : 'Max.MaxResult',
    'com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard$ScatterPlot3DCardResult' : 'ScatterPlot3DCard.ScatterPlot3DCardResult',
    'com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard$ScatterPlot3DSeries' : 'ScatterPlot3DCard.ScatterPlot3DSeries',
    'com.dataiku.dip.eda.worksheets.cards.ScatterPlot3DCard' : 'ScatterPlot3DCard',
    'com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard$UnitRootTestZACardResult' : 'UnitRootTestZACard.UnitRootTestZACardResult',
    'com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard' : 'UnitRootTestZACard',
    'com.dataiku.dip.code.CodeEnvModel$UsedCodeEnvRef' : 'CodeEnvModel.UsedCodeEnvRef',
    'com.dataiku.dip.code.CodeEnvModel$EnvLang' : 'CodeEnvModel.EnvLang',
    'com.dataiku.dip.code.CodeEnvModel$EnvImportSpecificationMode' : 'CodeEnvModel.EnvImportSpecificationMode',
    'com.dataiku.dip.eda.worksheets.cards.PairwiseMoodTestCard' : 'PairwiseMoodTestCard',
    'com.dataiku.dip.eda.worksheets.cards.PairwiseMoodTestCard$PairwiseMoodTestCardResult' : 'PairwiseMoodTestCard.PairwiseMoodTestCardResult',
    'com.dataiku.dip.eda.compute.curves.Curve$ParametrizedCurve' : 'Curve.ParametrizedCurve',
    'com.dataiku.dip.eda.compute.curves.Curve' : 'Curve',
    'com.dataiku.dip.analysis.model.prediction.NumericalHyperparameterDimension$Range' : 'NumericalHyperparameterDimension.Range',
    'com.dataiku.dip.analysis.model.prediction.NumericalHyperparameterDimension$ValueMode' : 'NumericalHyperparameterDimension.ValueMode',
    'com.dataiku.dip.analysis.model.prediction.NumericalHyperparameterDimension$Limit' : 'NumericalHyperparameterDimension.Limit',
    'com.dataiku.dip.analysis.model.prediction.NumericalHyperparameterDimension$Scaling' : 'NumericalHyperparameterDimension.Scaling',
    'com.dataiku.dip.analysis.model.prediction.NumericalHyperparameterDimension' : 'NumericalHyperparameterDimension',
    'com.dataiku.dip.server.datasets.DatasetMetricsStatusService$DatasetSummaryStatus' : 'DatasetMetricsStatusService.DatasetSummaryStatus',
    'com.dataiku.dip.eda.compute.computations.multivariate.PCA' : 'PCA',
    'com.dataiku.dip.eda.compute.computations.multivariate.PCA$PCAResult' : 'PCA.PCAResult',
    'com.dataiku.dip.experimenttracking.MLflowExperimentRunOrigin' : 'MLflowExperimentRunOrigin',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard' : 'QuantilesTableCard',
    'com.dataiku.dip.eda.worksheets.cards.univariate_header.QuantilesTableCard$QuantilesTableCardResult' : 'QuantilesTableCard.QuantilesTableCardResult',
    'com.dataiku.dip.analysis.coreservices.DataAugmentationService$AugmentationType' : 'DataAugmentationService.AugmentationType',
    'com.dataiku.dip.analysis.coreservices.DataAugmentationService$AugmentedImage' : 'DataAugmentationService.AugmentedImage',
    'com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova$OneWayAnovaResult' : 'OneWayAnova.OneWayAnovaResult',
    'com.dataiku.dip.eda.compute.computations.univariate.OneWayAnova' : 'OneWayAnova',
    'com.dataiku.dip.eda.compute.computations.univariate.Skewness$SkewnessResult' : 'Skewness.SkewnessResult',
    'com.dataiku.dip.eda.compute.computations.univariate.Skewness' : 'Skewness',
    'com.dataiku.dip.eda.compute.distributions.Distribution2D$FittedDistribution2D' : 'Distribution2D.FittedDistribution2D',
    'com.dataiku.dip.eda.compute.distributions.Distribution2D' : 'Distribution2D',
    'com.dataiku.dip.analysis.ml.prediction.guess.AnalyseUtils$TreatmentEffectSum' : 'AnalyseUtils.TreatmentEffectSum',
    'com.dataiku.dip.labeling.LabelingAnswer$AnswerStatus' : 'LabelingAnswer.AnswerStatus',
    'com.dataiku.dip.labeling.LabelingAnswer' : 'LabelingAnswer',
    'com.dataiku.dip.pivot.backend.model.maps.PTMapAggrGridRequest' : 'PTMapAggrGridRequest',
    'com.dataiku.dip.pivot.backend.model.maps.PTMapAggrGridResponse' : 'PTMapAggrGridResponse',
    'com.dataiku.dip.eda.compute.filtering.AllFilter' : 'AllFilter',
    'com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp$KsTest2SampResult' : 'KsTest2Samp.KsTest2SampResult',
    'com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp' : 'KsTest2Samp',
    'com.dataiku.dip.analysis.model.preprocessing.ImageFeaturePreprocessingParams$MissingHandlingMethod' : 'ImageFeaturePreprocessingParams.MissingHandlingMethod',
    'com.dataiku.dip.analysis.model.preprocessing.ImageFeaturePreprocessingParams$ImageHandlingMethod' : 'ImageFeaturePreprocessingParams.ImageHandlingMethod',
    'com.dataiku.dip.analysis.model.preprocessing.ImageFeaturePreprocessingParams' : 'ImageFeaturePreprocessingParams',
    'com.dataiku.dip.server.services.ReadOnlyJobsInternalDB$ObjectBuild' : 'ReadOnlyJobsInternalDB.ObjectBuild',
    'com.dataiku.dip.analysis.model.clustering.ClusteringModelPerf' : 'ClusteringModelPerf',
    'com.dataiku.dip.analysis.model.clustering.ClusteringModelPerf$Metrics' : 'ClusteringModelPerf.Metrics',
    'com.dataiku.dip.pivot.backend.model.boxplots.PTBoxplotsRequest$WhiskersMode' : 'PTBoxplotsRequest.WhiskersMode',
    'com.dataiku.dip.pivot.backend.model.boxplots.PTBoxplotsRequest' : 'PTBoxplotsRequest',
    'com.dataiku.dip.coremodel.SerializedDataset' : 'SerializedDataset',
    'com.dataiku.dip.coremodel.SerializedDataset$RemoteFilesSource' : 'SerializedDataset.RemoteFilesSource',
    'com.dataiku.dip.coremodel.SerializedDataset$SerializedCheck' : 'SerializedDataset.SerializedCheck',
    'com.dataiku.dip.coremodel.SerializedDataset$CrossProjectBuildBehaviour' : 'SerializedDataset.CrossProjectBuildBehaviour',
    'com.dataiku.dip.coremodel.SerializedDataset$RebuildBehavior' : 'SerializedDataset.RebuildBehavior',
    'com.dataiku.dip.coremodel.SerializedDataset$ReadWriteOptions' : 'SerializedDataset.ReadWriteOptions',
    'com.dataiku.dip.coremodel.SerializedDataset$RemoteFiles' : 'SerializedDataset.RemoteFiles',
    'com.dataiku.dip.coremodel.SerializedDataset$FlowOptions' : 'SerializedDataset.FlowOptions',
    'com.dataiku.dip.eda.compute.grouping.SubsampledGrouping' : 'SubsampledGrouping',
    'com.dataiku.dip.eda.compute.grouping.SubsampledGrouping$SubsampledGroupingResult' : 'SubsampledGrouping.SubsampledGroupingResult',
    'com.dataiku.dip.analysis.ml.clustering.guess.ClusteringGuessPolicy' : 'ClusteringGuessPolicy',
    'com.dataiku.dip.eda.compute.computations.univariate.FitDistribution$FitDistributionResult' : 'FitDistribution.FitDistributionResult',
    'com.dataiku.dip.eda.compute.computations.univariate.FitDistribution' : 'FitDistribution',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubImageClassificationPredictionModelPerf' : 'DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve$Curve' : 'DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PrecisionRecallCurve.Curve',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf' : 'DeepHubPredictionModelPerf',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf' : 'DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PerIOUMetrics' : 'DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PerIOUMetrics',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubImageClassificationPredictionModelPerf$ImageClassificationMetrics' : 'DeepHubPredictionModelPerf.DeepHubImageClassificationPredictionModelPerf.ImageClassificationMetrics',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve' : 'DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PrecisionRecallCurve',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$ObjectDetectionMetrics' : 'DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.ObjectDetectionMetrics',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$ConfusionMatrix' : 'DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.ConfusionMatrix',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf' : 'DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf',
    'com.dataiku.dip.analysis.model.prediction.DeepHubPredictionModelPerf$DeepHubObjectDetectionPredictionModelPerf$PerIOUPerf$PrecisionRecallCurve$BestConfidence' : 'DeepHubPredictionModelPerf.DeepHubObjectDetectionPredictionModelPerf.PerIOUPerf.PrecisionRecallCurve.BestConfidence',
    'com.dataiku.dip.coremodel.SerializedRecipe$SDep' : 'SerializedRecipe.SDep',
    'com.dataiku.dip.coremodel.SerializedRecipe$RecipeListItem' : 'SerializedRecipe.RecipeListItem',
    'com.dataiku.dip.coremodel.SerializedRecipe' : 'SerializedRecipe',
    'com.dataiku.dip.coremodel.SerializedRecipe$InputRole' : 'SerializedRecipe.InputRole',
    'com.dataiku.dip.coremodel.SerializedRecipe$RecipeInput' : 'SerializedRecipe.RecipeInput',
    'com.dataiku.dip.coremodel.SerializedRecipe$OutputRole' : 'SerializedRecipe.OutputRole',
    'com.dataiku.dip.coremodel.SerializedRecipe$RecipeOutput' : 'SerializedRecipe.RecipeOutput',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$TestDistributionResult' : 'TestDistribution.TestDistributionResult',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$ProbabilityMassFunction' : 'TestDistribution.ProbabilityMassFunction',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$ProbabilityDensityFunction' : 'TestDistribution.ProbabilityDensityFunction',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$LikelihoodTestsResult' : 'TestDistribution.LikelihoodTestsResult',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$PlotData' : 'TestDistribution.PlotData',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution' : 'TestDistribution',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$KolmogorovSmirnovTestResult' : 'TestDistribution.KolmogorovSmirnovTestResult',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$StatTests' : 'TestDistribution.StatTests',
    'com.dataiku.dip.eda.compute.computations.univariate.TestDistribution$QQPlotData' : 'TestDistribution.QQPlotData',
    'com.dataiku.dip.analysis.ml.prediction.split.SplitDesc' : 'SplitDesc',
    'com.dataiku.dip.eda.compute.distributions.Normal$FittedNormal' : 'Normal.FittedNormal',
    'com.dataiku.dip.eda.compute.distributions.Normal' : 'Normal',
    'com.dataiku.dip.experimenttracking.RunMetric' : 'RunMetric',
    'com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard' : 'UnitRootTestKPSSCard',
    'com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard$UnitRootTestKPSSCardResult' : 'UnitRootTestKPSSCard.UnitRootTestKPSSCardResult',
    'com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction' : 'CorrelationFunction',
    'com.dataiku.dip.eda.compute.computations.timeseries.CorrelationFunction$CorrelationFunctionResult' : 'CorrelationFunction.CorrelationFunctionResult',
    'com.dataiku.dip.eda.compute.computations.univariate.PairwiseTTest$PairwiseTTestResult' : 'PairwiseTTest.PairwiseTTestResult',
    'com.dataiku.dip.eda.compute.computations.univariate.PairwiseTTest' : 'PairwiseTTest',
    'com.dataiku.dip.coremodel.AppManifest' : 'AppManifest',
    'com.dataiku.dip.coremodel.AppManifest$AppInstancesFeatures' : 'AppManifest.AppInstancesFeatures',
    'com.dataiku.dip.coremodel.AppManifest$AppInstantiationPermission' : 'AppManifest.AppInstantiationPermission',
    'com.dataiku.dip.coremodel.AppManifest$AppHomepageSection' : 'AppManifest.AppHomepageSection',
    'com.dataiku.dip.coremodel.AppManifest$AppUseAsRecipeSettings$RoleMapping' : 'AppManifest.AppUseAsRecipeSettings.RoleMapping',
    'com.dataiku.dip.coremodel.AppManifest$AppProjectExportManifest' : 'AppManifest.AppProjectExportManifest',
    'com.dataiku.dip.coremodel.AppManifest$AppUseAsRecipeSettings' : 'AppManifest.AppUseAsRecipeSettings',
    'com.dataiku.dip.datasets.SamplingParam' : 'SamplingParam',
    'com.dataiku.dip.datasets.SamplingParam$SamplingMethod' : 'SamplingParam.SamplingMethod',
    'com.dataiku.dip.pivot.backend.dss.DoubleDataList' : 'DoubleDataList',
    'com.dataiku.dip.coremodel.InfoMessage$FixabilityCategory' : 'InfoMessage.FixabilityCategory',
    'com.dataiku.dip.coremodel.InfoMessage$Severity' : 'InfoMessage.Severity',
    'com.dataiku.dip.coremodel.InfoMessage$InfoMessages' : 'InfoMessage.InfoMessages',
    'com.dataiku.dip.coremodel.InfoMessage' : 'InfoMessage',
    'com.dataiku.dip.pivot.frontend.model.ChartType' : 'ChartType',
    'com.dataiku.dip.analysis.model.prediction.ClassicalPredictionModelDetails' : 'ClassicalPredictionModelDetails',
    'com.dataiku.dip.eda.compute.computations.univariate.TTest1Samp' : 'TTest1Samp',
    'com.dataiku.dip.eda.compute.computations.univariate.TTest1Samp$TTest1SampResult' : 'TTest1Samp.TTest1SampResult',
    'com.dataiku.dip.shaker.filter.FilterRequest$FilterType' : 'FilterRequest.FilterType',
    'com.dataiku.dip.shaker.facet.AlphanumLikeFacet' : 'AlphanumLikeFacet',
    'com.dataiku.dip.server.code.CodeEnvsUserController$EnvNamesWithProjectDefault' : 'CodeEnvsUserController.EnvNamesWithProjectDefault',
    'com.dataiku.dip.server.code.CodeEnvsUserController$ExperimentTrackingEnvNamesWithProjectDefault' : 'CodeEnvsUserController.ExperimentTrackingEnvNamesWithProjectDefault',
    'com.dataiku.dip.server.code.CodeEnvsUserController$EnvNameWithDesc' : 'CodeEnvsUserController.EnvNameWithDesc',
    'com.dataiku.dip.eda.worksheets.cards.TrivariateCard' : 'TrivariateCard',
    'com.dataiku.dip.eda.compute.distributions.NormalMixture$FittedNormalMixture$Component' : 'NormalMixture.FittedNormalMixture.Component',
    'com.dataiku.dip.eda.compute.distributions.NormalMixture$FittedNormalMixture' : 'NormalMixture.FittedNormalMixture',
    'com.dataiku.dip.eda.compute.distributions.NormalMixture' : 'NormalMixture',
    'com.dataiku.dip.pivot.backend.model.scatter.AbstractPTScatterRequest' : 'AbstractPTScatterRequest',
    'com.dataiku.dip.eda.compute.computations.bivariate.Covariance$CovarianceResult' : 'Covariance.CovarianceResult',
    'com.dataiku.dip.eda.compute.computations.bivariate.Covariance' : 'Covariance',
    'com.dataiku.dip.pivot.backend.model.DateAxisParams$BinningMode' : 'DateAxisParams.BinningMode',
    'com.dataiku.dip.pivot.backend.model.DateAxisParams' : 'DateAxisParams',
    'com.dataiku.dip.analysis.model.prediction.WeightParams' : 'WeightParams',
    'com.dataiku.dip.analysis.model.prediction.WeightParams$WeightMethod' : 'WeightParams.WeightMethod',
    'com.dataiku.dip.analysis.model.clustering.ResolvedClusteringPreprocessingParams' : 'ResolvedClusteringPreprocessingParams',
    'com.dataiku.dip.projects.importexport.model.BundleContainerSettings$PythonEnvsBehavior' : 'BundleContainerSettings.PythonEnvsBehavior',
    'com.dataiku.dip.projects.importexport.model.BundleContainerSettings' : 'BundleContainerSettings',
    'com.dataiku.dip.projects.importexport.model.BundleContainerSettings$PythonEnvImportTimeMode' : 'BundleContainerSettings.PythonEnvImportTimeMode',
    'com.dataiku.dip.eda.worksheets.cards.fragments.BivariateFrequencyTableFragment' : 'BivariateFrequencyTableFragment',
    'com.dataiku.dip.analysis.model.prediction.CategoricalHyperparameterDimension' : 'CategoricalHyperparameterDimension',
    'com.dataiku.dip.eda.worksheets.cards.OneWayANOVACard$OneWayANOVACardResult' : 'OneWayANOVACard.OneWayANOVACardResult',
    'com.dataiku.dip.eda.worksheets.cards.OneWayANOVACard' : 'OneWayANOVACard',
    'com.dataiku.dip.eda.worksheets.cards.UnivariateCard' : 'UnivariateCard',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest$UnitRootTestResult' : 'UnitRootTest.UnitRootTestResult',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTest' : 'UnitRootTest',
    'com.dataiku.dip.pivot.backend.dss.AxisHandler$AxisElt' : 'AxisHandler.AxisElt',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer' : 'AbstractCardContainer',
    'com.dataiku.dip.eda.worksheets.cards.common.AbstractCardContainer$AbstractCardContainerResult' : 'AbstractCardContainer.AbstractCardContainerResult',
    'com.dataiku.dip.server.services.NavigatorService$SavedModelFullInfo' : 'NavigatorService.SavedModelFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ObjectLink' : 'NavigatorService.ObjectLink',
    'com.dataiku.dip.server.services.NavigatorService$WorksheetFullInfo' : 'NavigatorService.WorksheetFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$WebAppFullInfo' : 'NavigatorService.WebAppFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ComputableFullInfo' : 'NavigatorService.ComputableFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ReportFullInfo' : 'NavigatorService.ReportFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$RecipeFullInfo' : 'NavigatorService.RecipeFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ModelEvaluationStoreFullInfo' : 'NavigatorService.ModelEvaluationStoreFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$AnalysisFullInfo' : 'NavigatorService.AnalysisFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$TaggableObjectFullInfo' : 'NavigatorService.TaggableObjectFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$LabelingTaskFullInfo' : 'NavigatorService.LabelingTaskFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ManagedFolderFullInfo' : 'NavigatorService.ManagedFolderFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$CodeStudioObjectFullInfo' : 'NavigatorService.CodeStudioObjectFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$DashboardFullInfo' : 'NavigatorService.DashboardFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ModelComparisonFullInfo' : 'NavigatorService.ModelComparisonFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$ZoneFullInfo' : 'NavigatorService.ZoneFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$NotebookFullInfo' : 'NavigatorService.NotebookFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$InsightFullInfo' : 'NavigatorService.InsightFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$DatasetFullInfo' : 'NavigatorService.DatasetFullInfo',
    'com.dataiku.dip.server.services.NavigatorService$StreamingEndpointFullInfo' : 'NavigatorService.StreamingEndpointFullInfo',
    'com.dataiku.dip.eda.worksheets.cards.common.SummaryCardBuilder$ComputedStatType' : 'SummaryCardBuilder.ComputedStatType',
    'com.dataiku.dip.eda.worksheets.cards.common.SummaryCardBuilder$ComputedStat' : 'SummaryCardBuilder.ComputedStat',
    'com.dataiku.dip.pivot.backend.model.PivotTableRequest$RequestType' : 'PivotTableRequest.RequestType',
    'com.dataiku.dip.pivot.backend.model.PivotTableRequest' : 'PivotTableRequest',
    'com.dataiku.dip.eda.worksheets.cards.CardResult$UnavailableCardResult' : 'CardResult.UnavailableCardResult',
    'com.dataiku.dip.eda.worksheets.cards.CardResult' : 'CardResult',
    'com.dataiku.dip.eda.worksheets.cards.CardResult$UnavailabilityReason' : 'CardResult.UnavailabilityReason',
    'com.dataiku.dip.server.UsabilityComputer$ListSettings' : 'UsabilityComputer.ListSettings',
    'com.dataiku.dip.server.UsabilityComputer$UsableComputable' : 'UsabilityComputer.UsableComputable',
    'com.dataiku.dip.server.UsabilityComputer$Usable' : 'UsabilityComputer.Usable',
    'com.dataiku.dip.analysis.model.prediction.CategoricalHyperparameterValue' : 'CategoricalHyperparameterValue',
    'com.dataiku.dip.shaker.ProcessorWithRecordedReport$ProcessorRecordedReport' : 'ProcessorWithRecordedReport.ProcessorRecordedReport',
    'com.dataiku.dip.analysis.model.core.SavedModelOriginInfo$Origin' : 'SavedModelOriginInfo.Origin',
    'com.dataiku.dip.analysis.model.core.SavedModelOriginInfo' : 'SavedModelOriginInfo',
    'com.dataiku.dip.eda.compute.grouping.AnumGrouping$AnumGroupingResult' : 'AnumGrouping.AnumGroupingResult',
    'com.dataiku.dip.eda.compute.grouping.AnumGrouping' : 'AnumGrouping',
    'com.dataiku.dip.pivot.backend.model.Unaggregated' : 'Unaggregated',
    'com.dataiku.dip.pivot.backend.model.Unaggregated$DateMode' : 'Unaggregated.DateMode',
    'com.dataiku.dip.pivot.backend.model.GeometryColumn' : 'GeometryColumn',
    'com.dataiku.dip.pivot.frontend.model.DisplayType' : 'DisplayType',
    'com.dataiku.dip.analysis.model.SplitParams' : 'SplitParams',
    'com.dataiku.dip.analysis.model.SplitParams$EFDSplit' : 'SplitParams.EFDSplit',
    'com.dataiku.dip.analysis.model.SplitParams$EFSDSplit' : 'SplitParams.EFSDSplit',
    'com.dataiku.dip.analysis.model.SplitParams$EFTDSplit' : 'SplitParams.EFTDSplit',
    'com.dataiku.dip.analysis.model.SplitParams$TrainTestPolicy' : 'SplitParams.TrainTestPolicy',
    'com.dataiku.dip.analysis.model.SplitParams$SplitMode' : 'SplitParams.SplitMode',
    'com.dataiku.dip.experimenttracking.RunParam' : 'RunParam',
    'com.dataiku.dip.pivot.backend.model.boxplots.PTBoxplotsResponse' : 'PTBoxplotsResponse',
    'com.dataiku.dip.pivot.backend.model.boxplots.PTBoxplotsResponse$Boxplot' : 'PTBoxplotsResponse.Boxplot',
    'com.dataiku.dip.pivot.backend.model.boxplots.PTBoxplotsResponse$BoxplotWithLabel' : 'PTBoxplotsResponse.BoxplotWithLabel',
    'com.dataiku.dip.eda.compute.computations.ComputationResult$ComputationCounts' : 'ComputationResult.ComputationCounts',
    'com.dataiku.dip.eda.compute.computations.ComputationResult' : 'ComputationResult',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateBoxPlotCard' : 'BivariateBoxPlotCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateBoxPlotCard$BivariateBoxPlotCardResult' : 'BivariateBoxPlotCard.BivariateBoxPlotCardResult',
    'com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard$WorksheetRootCardResult' : 'WorksheetRootCard.WorksheetRootCardResult',
    'com.dataiku.dip.eda.worksheets.cards.WorksheetRootCard' : 'WorksheetRootCard',
    'com.dataiku.dip.analysis.model.preprocessing.PreprocessingParams' : 'PreprocessingParams',
    'com.dataiku.dip.analysis.model.CompatibilityWithReason' : 'CompatibilityWithReason',
    'com.dataiku.dip.coremodel.Zone' : 'Zone',
    'com.dataiku.dip.partitioning.Dimension' : 'Dimension',
    'com.dataiku.dip.eda.compute.filtering.Filter' : 'Filter',
    'com.dataiku.dip.eda.compute.computations.Computation$UnivariateComputation' : 'Computation.UnivariateComputation',
    'com.dataiku.dip.eda.compute.computations.Computation$BivariateComputation' : 'Computation.BivariateComputation',
    'com.dataiku.dip.eda.compute.computations.Computation' : 'Computation',
    'com.dataiku.dip.eda.compute.computations.Computation$MultivariateComputation' : 'Computation.MultivariateComputation',
    'com.dataiku.dip.eda.worksheets.cards.FitDistributionCard' : 'FitDistributionCard',
    'com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$RequestedDistribution' : 'FitDistributionCard.RequestedDistribution',
    'com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$SingleFitResult' : 'FitDistributionCard.SingleFitResult',
    'com.dataiku.dip.eda.worksheets.cards.FitDistributionCard$FitDistributionCardResult' : 'FitDistributionCard.FitDistributionCardResult',
    'com.dataiku.dip.pivot.frontend.model.YAxisMode' : 'YAxisMode',
    'com.dataiku.dip.analysis.model.prediction.ResolvedTimeseriesForecastingCoreParams' : 'ResolvedTimeseriesForecastingCoreParams',
    'com.dataiku.dip.analysis.model.core.ResolvedCoreParams' : 'ResolvedCoreParams',
    'com.dataiku.dip.eda.worksheets.cards.TZTest1SampCard' : 'TZTest1SampCard',
    'com.dataiku.dip.eda.worksheets.cards.TZTest1SampCard$TZTest1SampCardResult' : 'TZTest1SampCard.TZTest1SampCardResult',
    'com.dataiku.dip.eda.compute.curves.IsotonicCurve$ParametrizedIsotonicCurve' : 'IsotonicCurve.ParametrizedIsotonicCurve',
    'com.dataiku.dip.eda.compute.curves.IsotonicCurve' : 'IsotonicCurve',
    'com.dataiku.dip.datasets.DatasetSelectionToMemTable' : 'DatasetSelectionToMemTable',
    'com.dataiku.dip.externalml.mlflow.MLflowModelDeployResult' : 'MLflowModelDeployResult',
    'com.dataiku.dip.pivot.frontend.model.ChartFilter$ExplicitCondition' : 'ChartFilter.ExplicitCondition',
    'com.dataiku.dip.pivot.frontend.model.ChartFilter$DateFilterPart' : 'ChartFilter.DateFilterPart',
    'com.dataiku.dip.pivot.frontend.model.ChartFilter$FilterType' : 'ChartFilter.FilterType',
    'com.dataiku.dip.pivot.frontend.model.ChartFilter$DateRelativeOption' : 'ChartFilter.DateRelativeOption',
    'com.dataiku.dip.pivot.frontend.model.ChartFilter$DateFilterType' : 'ChartFilter.DateFilterType',
    'com.dataiku.dip.pivot.frontend.model.ChartFilter' : 'ChartFilter',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard' : 'BivariateHistogramCard',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard$BivariateHistogramData' : 'BivariateHistogramCard.BivariateHistogramData',
    'com.dataiku.dip.eda.worksheets.cards.bivariate_header.BivariateHistogramCard$BivariateHistogramCardResult' : 'BivariateHistogramCard.BivariateHistogramCardResult',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelPerf$GlobalMetrics' : 'PredictionModelPerf.GlobalMetrics',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelPerf$HistogramBin' : 'PredictionModelPerf.HistogramBin',
    'com.dataiku.dip.analysis.model.prediction.PredictionModelPerf' : 'PredictionModelPerf',
    'com.dataiku.dip.eda.worksheets.cards.MoodTestNSampCard' : 'MoodTestNSampCard',
    'com.dataiku.dip.eda.worksheets.cards.MoodTestNSampCard$MoodTestNSampCardResult' : 'MoodTestNSampCard.MoodTestNSampCardResult',
    'com.dataiku.dip.coremodel.SimpleKeyValue' : 'SimpleKeyValue',
    'com.dataiku.dip.analysis.model.core.TrainExecutionParams' : 'TrainExecutionParams',
    'com.dataiku.dip.pivot.frontend.model.ChartVariant' : 'ChartVariant',
    'com.dataiku.dip.eda.compute.distributions.Weibull' : 'Weibull',
    'com.dataiku.dip.eda.compute.distributions.Weibull$FittedWeibull' : 'Weibull.FittedWeibull',
    'com.dataiku.dip.experimenttracking.RunTag' : 'RunTag',
    'com.dataiku.dip.server.controllers.StreamingEndpointsController$StreamingEndpointListItem' : 'StreamingEndpointsController.StreamingEndpointListItem',
    'com.dataiku.dip.eda.compute.computations.univariate.MoodTestNSamp' : 'MoodTestNSamp',
    'com.dataiku.dip.eda.compute.computations.univariate.MoodTestNSamp$MoodTestNSampResult' : 'MoodTestNSamp.MoodTestNSampResult',
    'com.dataiku.dip.input.DatasetOrdering$DatasetOrderingRule' : 'DatasetOrdering.DatasetOrderingRule',
    'com.dataiku.dip.input.DatasetOrdering' : 'DatasetOrdering',
    'com.dataiku.dip.eda.worksheets.cards.BivariateCard' : 'BivariateCard',
    'com.dataiku.dip.coremodel.Checklists' : 'Checklists',
    'com.dataiku.dip.pivot.frontend.model.Multiplier' : 'Multiplier',
    'com.dataiku.dip.eda.compute.filtering.MissingValueFilter' : 'MissingValueFilter',
    'com.dataiku.dip.analysis.model.core.CustomEvaluationMetric' : 'CustomEvaluationMetric',
    'com.dataiku.dip.util.AnyLoc' : 'AnyLoc',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF$UnitRootTestADFResult' : 'UnitRootTestADF.UnitRootTestADFResult',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF$RegressionMode' : 'UnitRootTestADF.RegressionMode',
    'com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF' : 'UnitRootTestADF',
    'com.dataiku.dip.datasets.Type' : 'Type',
    'com.dataiku.dip.eda.compute.distributions.Poisson' : 'Poisson',
    'com.dataiku.dip.eda.compute.distributions.Poisson$FittedPoisson' : 'Poisson.FittedPoisson',
    'com.dataiku.dip.coremodel.Schema' : 'Schema',
    'com.dataiku.dip.analysis.ml.interactivemodel.InteractiveModelResponse$InteractiveModelResponsePayload' : 'InteractiveModelResponse.InteractiveModelResponsePayload',
    'com.dataiku.dip.coremodel.SchemaColumn' : 'SchemaColumn',
    'com.dataiku.dip.dao.GeneralSettingsDAO$SoftHardLimit' : 'GeneralSettingsDAO.SoftHardLimit',
    'com.dataiku.dip.dao.GeneralSettingsDAO$ProjectDefaultDatasetCreationSettings' : 'GeneralSettingsDAO.ProjectDefaultDatasetCreationSettings',
    'com.dataiku.dip.dao.GeneralSettingsDAO$AbstractDefaultDatasetCreationSettings' : 'GeneralSettingsDAO.AbstractDefaultDatasetCreationSettings',
    'com.dataiku.dip.dao.GeneralSettingsDAO$InheritableEnabledSetting$LocalValue' : 'GeneralSettingsDAO.InheritableEnabledSetting.LocalValue',
    'com.dataiku.dip.projects.importexport.ExportedProject$InstalledPluginRef' : 'ExportedProject.InstalledPluginRef',
    'com.dataiku.dip.pivot.backend.model.scatter.PTScatterResponse$ScatterAxis' : 'PTScatterResponse.ScatterAxis',
    'com.dataiku.dip.pivot.backend.model.scatter.PTScatterResponse' : 'PTScatterResponse',
    'com.dataiku.dip.experimenttracking.RunInfo' : 'RunInfo',
    'com.dataiku.dip.eda.worksheets.queries.ListMostFrequentValues$ListMostFrequentValuesResult' : 'ListMostFrequentValues.ListMostFrequentValuesResult',
    'com.dataiku.dip.eda.worksheets.queries.ListMostFrequentValues' : 'ListMostFrequentValues',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$Unaggregated' : 'ChartDef.Unaggregated',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$LegendPlacement' : 'ChartDef.LegendPlacement',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplaySubTotals' : 'ChartDef.PivotTableDisplaySubTotals',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$GeometryLayer' : 'ChartDef.GeometryLayer',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions' : 'ChartDef.ColorOptions',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$ContinuousColorScaleMode' : 'ChartDef.ColorOptions.ContinuousColorScaleMode',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$HexbinRadiusMode' : 'ChartDef.HexbinRadiusMode',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$ScatterOptions' : 'ChartDef.ScatterOptions',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$Palette' : 'ChartDef.ColorOptions.Palette',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$Geometry' : 'ChartDef.Geometry',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$BubblesOptions' : 'ChartDef.BubblesOptions',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$XAxisMode' : 'ChartDef.XAxisMode',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$PieOptions' : 'ChartDef.PieOptions',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$PivotMeasureDisplayMode' : 'ChartDef.PivotMeasureDisplayMode',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplayGrandTotals' : 'ChartDef.PivotTableDisplayGrandTotals',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$ComputeMode' : 'ChartDef.ComputeMode',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$MapGridOptions' : 'ChartDef.MapGridOptions',
    'com.dataiku.dip.pivot.frontend.model.ChartDef' : 'ChartDef',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$CustomAxisExtent' : 'ChartDef.CustomAxisExtent',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$ExtentEditMode' : 'ChartDef.ExtentEditMode',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$BubbleShape' : 'ChartDef.BubbleShape',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$YAxisMode' : 'ChartDef.YAxisMode',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$MapOptions' : 'ChartDef.MapOptions',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$QuantizationMode' : 'ChartDef.ColorOptions.QuantizationMode',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$ColorOptions$PaletteType' : 'ChartDef.ColorOptions.PaletteType',
    'com.dataiku.dip.pivot.frontend.model.ChartDef$PivotTableDisplayTotals' : 'ChartDef.PivotTableDisplayTotals',
    'com.dataiku.dip.analysis.model.prediction.ResolvedPredictionPreprocessingParams' : 'ResolvedPredictionPreprocessingParams',
    'com.dataiku.dip.analysis.model.prediction.TimeseriesEvaluationForecasts$EvaluationForecasts' : 'TimeseriesEvaluationForecasts.EvaluationForecasts',
    'com.dataiku.dip.analysis.model.prediction.TimeseriesEvaluationForecasts' : 'TimeseriesEvaluationForecasts',
    'com.dataiku.dip.analysis.model.prediction.TimeseriesEvaluationForecasts$Quantile' : 'TimeseriesEvaluationForecasts.Quantile',
    'com.dataiku.dip.analysis.model.prediction.PreTrainPredictionModelingParams$Algorithm' : 'PreTrainPredictionModelingParams.Algorithm',
    'com.dataiku.dip.analysis.model.prediction.PreTrainPredictionModelingParams' : 'PreTrainPredictionModelingParams',
    'com.dataiku.dip.code.CodeEnvSelection' : 'CodeEnvSelection',
    'com.dataiku.dip.code.CodeEnvSelection$EnvMode' : 'CodeEnvSelection.EnvMode',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$EvaluationMetric' : 'ClusteringModelingParams.EvaluationMetric',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$KMeansParams' : 'ClusteringModelingParams.KMeansParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams' : 'ClusteringModelingParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$SpectralParams' : 'ClusteringModelingParams.SpectralParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$GMMParams' : 'ClusteringModelingParams.GMMParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$MLLibGaussianMixtureParams' : 'ClusteringModelingParams.MLLibGaussianMixtureParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$H2OKMeansModelingParams' : 'ClusteringModelingParams.H2OKMeansModelingParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$VerticaKMeansParams' : 'ClusteringModelingParams.VerticaKMeansParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$MetricParams' : 'ClusteringModelingParams.MetricParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$ClusteringCustomPythonParams' : 'ClusteringModelingParams.ClusteringCustomPythonParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$WardParams' : 'ClusteringModelingParams.WardParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$MLLibKMeansParams' : 'ClusteringModelingParams.MLLibKMeansParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$MiniBatchKMeansParams' : 'ClusteringModelingParams.MiniBatchKMeansParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$IsolationForestParams' : 'ClusteringModelingParams.IsolationForestParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$DBScanParams' : 'ClusteringModelingParams.DBScanParams',
    'com.dataiku.dip.analysis.model.ClusteringModelingParams$TwoStepParams' : 'ClusteringModelingParams.TwoStepParams',
    'com.dataiku.dip.eda.worksheets.cards.CDFPlotCard$CDFItem' : 'CDFPlotCard.CDFItem',
    'com.dataiku.dip.eda.worksheets.cards.CDFPlotCard$CDFPlotCardResult' : 'CDFPlotCard.CDFPlotCardResult',
    'com.dataiku.dip.eda.worksheets.cards.CDFPlotCard' : 'CDFPlotCard',
    'com.dataiku.dip.eda.compute.filtering.AndFilter' : 'AndFilter',
    'com.dataiku.dip.pivot.backend.model.PivotTableAggregatedRequest' : 'PivotTableAggregatedRequest',
    'com.dataiku.dip.eda.compute.computations.UnavailableResult' : 'UnavailableResult',
    'com.dataiku.dip.shaker.model.GroupScriptStep' : 'GroupScriptStep',
    'com.dataiku.dip.eda.worksheets.queries.SuggestCards' : 'SuggestCards',
    'com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestCardsResult' : 'SuggestCards.SuggestCardsResult',
    'com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestedCard' : 'SuggestCards.SuggestedCard',
    'com.dataiku.dip.eda.worksheets.queries.SuggestCards$SuggestedVariable' : 'SuggestCards.SuggestedVariable',
    'com.dataiku.dip.eda.worksheets.queries.SuggestCards$AssistantSettings' : 'SuggestCards.AssistantSettings',
    'com.dataiku.dip.eda.compute.computations.univariate.TimeStep' : 'TimeStep',
    'com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult$Step' : 'TimeStep.TimeStepResult.Step',
    'com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult$BusinessDaysStep' : 'TimeStep.TimeStepResult.BusinessDaysStep',
    'com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult' : 'TimeStep.TimeStepResult',
    'com.dataiku.dip.eda.compute.computations.univariate.TimeStep$TimeStepResult$IsoDurationStep' : 'TimeStep.TimeStepResult.IsoDurationStep',
    'com.dataiku.dip.analysis.model.core.ModelUserMeta' : 'ModelUserMeta',
    'com.dataiku.dip.analysis.model.core.ModelUserMeta$ClusterMeta' : 'ModelUserMeta.ClusterMeta',
    'com.dataiku.dip.analysis.model.prediction.DeepHubColumnFormat$ObjectDetectionTargetItem' : 'DeepHubColumnFormat.ObjectDetectionTargetItem',
    'com.dataiku.dip.analysis.model.prediction.DeepHubColumnFormat$ObjectDetectionPredictedItem' : 'DeepHubColumnFormat.ObjectDetectionPredictedItem',
    'com.dataiku.dip.analysis.model.prediction.DeepHubColumnFormat$EnrichedObjectDetectionPairedItem' : 'DeepHubColumnFormat.EnrichedObjectDetectionPairedItem',
    'com.dataiku.dip.analysis.model.prediction.DeepHubColumnFormat$PairingItem' : 'DeepHubColumnFormat.PairingItem',
    'com.dataiku.dip.analysis.model.ModelTrainInfo$PostSearchDescription' : 'ModelTrainInfo.PostSearchDescription',
    'com.dataiku.dip.analysis.model.ModelTrainInfo$PreSearchDescription' : 'ModelTrainInfo.PreSearchDescription',
    'com.dataiku.dip.analysis.model.ModelTrainInfo$ModelTrainState' : 'ModelTrainInfo.ModelTrainState',
    'com.dataiku.dip.analysis.model.ModelTrainInfo$TrainProgress$TopLevelDone' : 'ModelTrainInfo.TrainProgress.TopLevelDone',
    'com.dataiku.dip.analysis.model.ModelTrainInfo' : 'ModelTrainInfo',
    'com.dataiku.dip.analysis.model.ModelTrainInfo$SearchParam' : 'ModelTrainInfo.SearchParam',
    'com.dataiku.dip.analysis.model.ModelTrainInfo$TrainProgress' : 'ModelTrainInfo.TrainProgress',
    'com.dataiku.dip.eda.compute.distributions.LogNormal' : 'LogNormal',
    'com.dataiku.dip.eda.compute.distributions.LogNormal$FittedLogNormal' : 'LogNormal.FittedLogNormal'

};
export * from './src/main/java/com/dataiku/dip/experimenttracking/model';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/column-summary';
export * from './src/main/java/com/dataiku/dip/analysis/ml/smstatus';
export * from './src/main/java/com/dataiku/dip/labeling/score/labeling-score-computer';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/resolved-timeseries-forecasting-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/actual-model-parameters';
export * from './src/main/java/com/dataiku/dip/shaker/server/serialized-mem-table-v2';
export * from './src/main/java/com/dataiku/dip/projects/importexport/model/project-export-options';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/unit-root-test-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/chi2-ind-test';
export * from './src/main/java/com/dataiku/dip/analysis/ml/mldiagnostics';
export * from './src/main/java/com/dataiku/dip/code/project-code-envs-selection';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/time-series-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/numerical-histogram-card';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/axis-def';
export * from './src/main/java/com/dataiku/dip/analysis/model/core/resolved-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/column-card';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/axis-sort-prune';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fragments/time-info-fragment';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/distribution';
export * from './src/main/java/com/dataiku/dip/datasets/dataset-selection';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/prediction-mltask';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/sign-test1-samp';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/multivariate/fetch-values';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/pareto';
export * from './src/main/java/com/dataiku/dip/dashboards/model/dashboard';
export * from './src/main/java/com/dataiku/dip/labeling/region/region-element';
export * from './src/main/java/com/dataiku/dip/labeling/objectdetection/bounding-box';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/univariate-header-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/shapiro';
export * from './src/main/java/com/dataiku/dip/shaker/model/serialized-shaker-script';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/unit-root-test-adfcard';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fit-curve-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/grouping';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/mood-test2-samp-card';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/ensemble-params';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/timeseries/pacf';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing-report';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/series';
export * from './src/main/java/com/dataiku/dip/server/services/taggable-objects-service';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/filter-facet';
export * from './src/main/java/com/dataiku/dip/coremodel/object-custom-metadata';
export * from './src/main/java/com/dataiku/dip/shaker/server/serialized-table-chunk';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/deep-hub-pre-train-modeling-params';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/correlation-matrix-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/abstract2-dpivot-card';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/deep-hub-prediction-model-details';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/univariate-summary-stats-card';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/pivot-table-webapp-request';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/time-ordering-params';
export * from './src/main/java/com/dataiku/dip/shaker/model/processor-script-step';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/common/multi-computation';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/ttest2-samp-card';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/density/pt2-ddensity-request';
export * from './src/main/java/com/dataiku/dip/coremodel/exposed-object';
export * from './src/main/java/com/dataiku/dip/analysis/model/clustering/pre-train-clustering-modeling-params';
export * from './src/main/java/com/dataiku/dip/projects/importexport/model/bundle-exporter-settings';
export * from './src/main/java/com/dataiku/dip/labeling/verified-labeling-answer';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/interval-filter';
export * from './src/main/java/com/dataiku/dip/server/datasets/datasets-crudcontroller';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/row-filter';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/ks-test2-samp-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/mosaic-plot-card';
export * from './dss-core/src/main/java/com/dataiku/dip/dataflow/exec/filter/filter-desc';
export * from './src/main/java/com/dataiku/dip/dashboards/model/ui/enriched-dashboard-page';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/measure-def';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/cat-feature-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/ptmap-raw-geometry-response';
export * from './src/main/java/com/dataiku/dip/experimenttracking/run';
export * from './dss-core/src/main/java/com/dataiku/dip/partitioning/partitioning-scheme';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/shapiro-normality-test-card';
export * from './src/main/java/com/dataiku/dip/labeling/base-labeling-answer';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/binary-classification-model-perf';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/prediction-modeling-params';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/exponential';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/pairwise-ttest-card';
export * from './src/main/java/com/dataiku/dip/experimenttracking/experiment';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/common/grouped-computation';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/pivot-table-tensor-request';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/prediction-model-intrinsic-perf';
export * from './src/main/java/com/dataiku/dip/server/services/explores-service';
export * from './src/main/java/com/dataiku/dip/analysis/ml/spark-constants';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/feature-generation-params';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/timeseries/time-series-computation';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/timeseries-forecasting-model-details';
export * from './src/main/java/com/dataiku/dip/analysis/model/core/analysis-core-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/timeseries-forecasting-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/analysis/ml/prediction/deephub-images-data-service';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/timeseries-forecasting-model-perf';
export * from './src/main/java/com/dataiku/dip/server/services/jupyter-service';
export * from './src/main/java/com/dataiku/dip/shaker/analysis/numerical-variable-analysis';
export * from './src/main/java/com/dataiku/dip/labeling/entityextraction/named-entity';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/timeseries/mann-kendall-test';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/fit2-ddistribution';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/common/count-groups';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/bivariate-summary-stats-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/empirical-cdf';
export * from './src/main/java/com/dataiku/dip/labeling/labeling-task-stats';
export * from './src/main/java/com/dataiku/dip/analysis/coreservices/analysis-crudservice';
export * from './src/main/java/com/dataiku/dip/projects/apps/apps-service';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/metric-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/tabular-prediction-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/dashboards/model/tile';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/beta';
export * from './src/main/java/com/dataiku/dip/dashboards/model/insight';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/pivot-table-filters-request';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/not-computed-result';
export * from './src/main/java/com/dataiku/dip/labeling/annotation';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/resolved-causal-prediction-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/eda/compute/utils/compacted-array';
export * from './src/main/java/com/dataiku/dip/analysis/model/clustering/heat-map';
export * from './src/main/java/com/dataiku/dip/labeling/labeling-task';
export * from './dss-core/src/main/java/com/dataiku/dip/partitioning/time-dimension';
export * from './src/main/java/com/dataiku/dip/server/services/projects-service';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/cross-grouping';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/text-feature-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/bivariate-frequency-table-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/kde2-d';
export * from './src/main/java/com/dataiku/dip/eda/compute/curves/polynomial-curve';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/min-time';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/queries/debug-card';
export * from './src/main/java/com/dataiku/dip/server/services/itagging-service';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/nadimension-def';
export * from './src/main/java/com/dataiku/dip/projects/importexport/model/active-bundle-state';
export * from './src/main/java/com/dataiku/dip/pivot/backend/dss/long-data-tensor';
export * from './src/main/java/com/dataiku/dip/containers/exec/container-exec-selection';
export * from './src/main/java/com/dataiku/dip/pivot/backend/dss/indexed-data-list';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/clustering-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/assertions/mlassertions-params';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/laplace';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/multivariate-card';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/timeseries-forecasting-model-intrinsic-perf';
export * from './src/main/java/com/dataiku/dip/experimenttracking/experiment-tag';
export * from './dss-core/src/main/java/com/dataiku/dip/partitioning/exact-value-dimension';
export * from './src/main/java/com/dataiku/dip/workspaces/workspace';
export * from './src/main/java/com/dataiku/dip/pivot/backend/dss/long-data-list';
export * from './src/main/java/com/dataiku/dip/analysis/model/mltask';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/pairwise-mood-test';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/ptmap-aggr-admin-request';
export * from './src/main/java/com/dataiku/dip/pivot/backend/dss/double-data-tensor';
export * from './src/main/java/com/dataiku/dip/analysis/model/model-details-base';
export * from './src/main/java/com/dataiku/dip/analysis/model/core/base-user-meta';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/resolved-classical-prediction-core-params';
export * from './src/main/java/com/dataiku/dip/experimenttracking/run-data';
export * from './src/main/java/com/dataiku/dip/analysis/model/clustering/resolved-clustering-core-params';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/mean';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/classical-prediction-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/spearman';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/ptmap-scatter-response';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/joint-normal';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/ptmap-raw-geometry-request';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/failed-result';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/timeseries/unit-root-test-za';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/anum-filter';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/timeseries/unit-root-test-kpss';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/variance';
export * from './src/main/java/com/dataiku/dip/server/controllers/projects-crudcontroller';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/fit-curve';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/resolved-causal-prediction-core-params';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/ptmap-aggr-response';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/calibration-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/prediction-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/server/services/dataset-notebook-service';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/common/dummy-computation';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/durbin-watson-card';
export * from './src/main/java/com/dataiku/dip/coremodel/checklist';
export * from './src/main/java/com/dataiku/dip/eda/eda-error-codes';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/data-evaluation-metrics';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/ztest1-samp';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/partitioned-model-params';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/ptmap-scatter-request';
export * from './src/main/java/com/dataiku/dip/dao/saved-model';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/pivot-table-webapp-response';
export * from './src/main/java/com/dataiku/dip/labeling/labeling-record';
export * from './src/main/java/com/dataiku/dip/shaker/model/script-step';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/abstract-nsamp-test-card';
export * from './src/main/java/com/dataiku/dip/analysis/model/clustering/clustering-model-details';
export * from './src/main/java/com/dataiku/dip/labeling/region/labeling-region';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/resolved-prediction-core-params';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/min';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/std-dev';
export * from './src/main/java/com/dataiku/dip/experimenttracking/run-status';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/causal-prediction-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/dimension-def';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/abstract-header-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/triangular';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fragments/box-plot-fragment';
export * from './src/main/java/com/dataiku/dip/analysis/model/causal-prediction-model-details';
export * from './src/main/java/com/dataiku/dip/server/controllers/jupyter-controller';
export * from './src/main/java/com/dataiku/dip/dao/interests-internal-db';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/playground-card';
export * from './src/main/java/com/dataiku/dip/analysis/ml/prediction/flow/prediction-smmgmt-service';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/vector-feature-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/models/variable';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/integer-hyperparameter-dimension';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/resolved-classical-prediction-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/timeseries/durbin-watson';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/num-feature-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/float-hyperparameter-dimension';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/compute-mode';
export * from './src/main/java/com/dataiku/dip/coremodel/recipe-engines-preference-config';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/sum';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/available-result';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/parallel-coordinates-plot-card';
export * from './src/main/java/com/dataiku/dip/server/services/accessible-objects-service';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/models/split-by-spec';
export * from './src/main/java/com/dataiku/dip/analysis/model/clustering/clusters-facts';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/kendall-tau';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/numerical-axis-params';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/grouped-compiled-card-wrapper';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/abstract2-samp-test-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/mutual-information';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/resolved-deep-hub-prediction-core-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/feature-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/labeling/classification/classification-annotation';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/sem';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/display-axis';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/categorical-histogram-card';
export * from './src/main/java/com/dataiku/dip/shaker/server/sample-metadata';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/deep-hub-metric-params';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/pivot-table-tensor-response';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/binomial';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/sign-test1-samp-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/pcacard';
export * from './src/main/java/com/dataiku/dip/shaker/text/string-normalization-mode';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/pivot-table-filters-response';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/scatter-plot-card';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/density/pt2-ddensity-response';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/odd-integer-hyperparameter-dimension';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/mann-kendall-test-card';
export * from './src/main/java/com/dataiku/dip/labeling/verified-label';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/heatmap-params';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/number-formatting-options';
export * from './src/main/java/com/dataiku/dip/coremodel/app-homepage-tile';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/grouping-result';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/causal-prediction-model-perf';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/not-filter';
export * from './src/main/java/com/dataiku/dip/shaker/facet/alphanum-facet-with-map';
export * from './src/main/java/com/dataiku/dip/analysis/model/clustering/clustering-mltask';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/scatter/ptscatter-request';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/top-ntime-grouping';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fit2-ddistribution-card';
export * from './src/main/java/com/dataiku/dip/shaker/server/data-service';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/models/worksheet';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/binned-grouping';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/acfplot-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/pearson';
export * from './src/main/java/com/dataiku/dip/coremodel/serialized-project';
export * from './src/main/java/com/dataiku/dip/coremodel/conditional-output';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/card';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/union-grouping';
export * from './src/main/java/com/dataiku/dip/projects/importexport/model/project-remapping-settings';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/queries/interactive-query';
export * from './src/main/java/com/dataiku/dip/analysis/ml/prediction/guess/prediction-guess-policy';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/pivot-table-response';
export * from './src/main/java/com/dataiku/dip/code/python-code-env-packages-utils';
export * from './src/main/java/com/dataiku/dip/eda/compute/sampling/sample';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/merge-grouping';
export * from './src/main/java/com/dataiku/dip/analysis/ml/clustering/flow/clustering-smmgmt-service';
export * from './src/main/java/com/dataiku/dip/dashboards/model/dashboard-page';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/abstract-pairwise-test-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/univariate-frequency-table-card';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/aggregation';
export * from './src/main/java/com/dataiku/dip/analysis/ml/mlspark-params';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/bivariate-header-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/sampling/data-spec';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fragments/count-complete-fragment';
export * from './src/main/java/com/dataiku/dip/labeling/label';
export * from './src/main/java/com/dataiku/dip/coremodel/version-tag';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/subset-grouping';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/worksheets-service';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/quantiles';
export * from './src/main/java/com/dataiku/dip/labeling/labeling-record-to-annotate';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/chi2-ind-test-card';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/multiclass-model-perf';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/kurtosis';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/timestep-params';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fragments/numerical-histogram-fragment';
export * from './src/main/java/com/dataiku/dip/datasets/streamable-dataset-selection';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/entropy';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/models/binning-mode';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/common/count';
export * from './src/main/java/com/dataiku/dip/dashboards/model/tiles-grid';
export * from './src/main/java/com/dataiku/dip/coremodel/head-with-versioning-info';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/max-time';
export * from './src/main/java/com/dataiku/dip/shaker/server/table-coloring-service';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/timeseries/acf';
export * from './src/main/java/com/dataiku/dip/eda/compute/stats/pvalue-adjustment-method';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/max';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/scatter-plot3-dcard';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/unit-root-test-zacard';
export * from './src/main/java/com/dataiku/dip/code/code-env-model';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/pairwise-mood-test-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/curves/curve';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/numerical-hyperparameter-dimension';
export * from './src/main/java/com/dataiku/dip/server/datasets/dataset-metrics-status-service';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/multivariate/pca';
export * from './src/main/java/com/dataiku/dip/experimenttracking/mlflow-experiment-run-origin';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate_header/quantiles-table-card';
export * from './src/main/java/com/dataiku/dip/analysis/coreservices/data-augmentation-service';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/one-way-anova';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/skewness';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/distribution2-d';
export * from './src/main/java/com/dataiku/dip/analysis/ml/prediction/guess/analyse-utils';
export * from './src/main/java/com/dataiku/dip/labeling/labeling-answer';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/ptmap-aggr-grid-request';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/maps/ptmap-aggr-grid-response';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/all-filter';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/ks-test2-samp';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/image-feature-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/server/services/read-only-jobs-internal-db';
export * from './src/main/java/com/dataiku/dip/analysis/model/clustering/clustering-model-perf';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/boxplots/ptboxplots-request';
export * from './src/main/java/com/dataiku/dip/coremodel/serialized-dataset';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/subsampled-grouping';
export * from './src/main/java/com/dataiku/dip/analysis/ml/clustering/guess/clustering-guess-policy';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/fit-distribution';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/deep-hub-prediction-model-perf';
export * from './src/main/java/com/dataiku/dip/coremodel/serialized-recipe';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/test-distribution';
export * from './src/main/java/com/dataiku/dip/analysis/ml/prediction/split/split-desc';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/normal';
export * from './src/main/java/com/dataiku/dip/experimenttracking/run-metric';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/unit-root-test-kpsscard';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/timeseries/correlation-function';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/pairwise-ttest';
export * from './src/main/java/com/dataiku/dip/coremodel/app-manifest';
export * from './src/main/java/com/dataiku/dip/datasets/sampling-param';
export * from './src/main/java/com/dataiku/dip/pivot/backend/dss/double-data-list';
export * from './dku-core/src/main/java/com/dataiku/dip/coremodel/info-message';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/chart-type';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/classical-prediction-model-details';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/ttest1-samp';
export * from './src/main/java/com/dataiku/dip/shaker/filter/filter-request';
export * from './src/main/java/com/dataiku/dip/shaker/facet/alphanum-like-facet';
export * from './src/main/java/com/dataiku/dip/server/code/code-envs-user-controller';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/trivariate-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/normal-mixture';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/scatter/abstract-ptscatter-request';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/bivariate/covariance';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/date-axis-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/weight-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/clustering/resolved-clustering-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/projects/importexport/model/bundle-container-settings';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fragments/bivariate-frequency-table-fragment';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/categorical-hyperparameter-dimension';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/one-way-anovacard';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/univariate-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/timeseries/unit-root-test';
export * from './src/main/java/com/dataiku/dip/pivot/backend/dss/axis-handler';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/abstract-card-container';
export * from './src/main/java/com/dataiku/dip/server/services/navigator-service';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/common/summary-card-builder';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/pivot-table-request';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/card-result';
export * from './src/main/java/com/dataiku/dip/server/usability-computer';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/categorical-hyperparameter-value';
export * from './src/main/java/com/dataiku/dip/shaker/processor-with-recorded-report';
export * from './src/main/java/com/dataiku/dip/analysis/model/core/saved-model-origin-info';
export * from './src/main/java/com/dataiku/dip/eda/compute/grouping/anum-grouping';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/unaggregated';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/geometry-column';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/display-type';
export * from './src/main/java/com/dataiku/dip/analysis/model/split-params';
export * from './src/main/java/com/dataiku/dip/experimenttracking/run-param';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/boxplots/ptboxplots-response';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/computation-result';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/bivariate-box-plot-card';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/worksheet-root-card';
export * from './src/main/java/com/dataiku/dip/analysis/model/preprocessing/preprocessing-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/compatibility-with-reason';
export * from './src/main/java/com/dataiku/dip/coremodel/zone';
export * from './dss-core/src/main/java/com/dataiku/dip/partitioning/dimension';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/filter';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/computation';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/fit-distribution-card';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/yaxis-mode';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/resolved-timeseries-forecasting-core-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/core/resolved-core-params';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/tztest1-samp-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/curves/isotonic-curve';
export * from './src/main/java/com/dataiku/dip/datasets/dataset-selection-to-mem-table';
export * from './src/main/java/com/dataiku/dip/externalml/mlflow/mlflow-model-deploy-result';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/chart-filter';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate_header/bivariate-histogram-card';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/prediction-model-perf';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/mood-test-nsamp-card';
export * from './dku-core/src/main/java/com/dataiku/dip/coremodel/simple-key-value';
export * from './src/main/java/com/dataiku/dip/analysis/model/core/train-execution-params';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/chart-variant';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/weibull';
export * from './src/main/java/com/dataiku/dip/experimenttracking/run-tag';
export * from './src/main/java/com/dataiku/dip/server/controllers/streaming-endpoints-controller';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/mood-test-nsamp';
export * from './dss-core/src/main/java/com/dataiku/dip/input/dataset-ordering';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/bivariate-card';
export * from './src/main/java/com/dataiku/dip/coremodel/checklists';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/multiplier';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/missing-value-filter';
export * from './src/main/java/com/dataiku/dip/analysis/model/core/custom-evaluation-metric';
export * from './src/main/java/com/dataiku/dip/util/any-loc';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/timeseries/unit-root-test-adf';
export * from './dss-core/src/main/java/com/dataiku/dip/datasets/type';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/poisson';
export * from './dss-core/src/main/java/com/dataiku/dip/coremodel/schema';
export * from './src/main/java/com/dataiku/dip/analysis/ml/interactivemodel/interactive-model-response';
export * from './dss-core/src/main/java/com/dataiku/dip/coremodel/schema-column';
export * from './src/main/java/com/dataiku/dip/dao/general-settings-dao';
export * from './src/main/java/com/dataiku/dip/projects/importexport/exported-project';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/scatter/ptscatter-response';
export * from './src/main/java/com/dataiku/dip/experimenttracking/run-info';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/queries/list-most-frequent-values';
export * from './src/main/java/com/dataiku/dip/pivot/frontend/model/chart-def';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/resolved-prediction-preprocessing-params';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/timeseries-evaluation-forecasts';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/pre-train-prediction-modeling-params';
export * from './src/main/java/com/dataiku/dip/code/code-env-selection';
export * from './src/main/java/com/dataiku/dip/analysis/model/clustering-modeling-params';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/cards/cdfplot-card';
export * from './src/main/java/com/dataiku/dip/eda/compute/filtering/and-filter';
export * from './src/main/java/com/dataiku/dip/pivot/backend/model/pivot-table-aggregated-request';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/unavailable-result';
export * from './src/main/java/com/dataiku/dip/shaker/model/group-script-step';
export * from './src/main/java/com/dataiku/dip/eda/worksheets/queries/suggest-cards';
export * from './src/main/java/com/dataiku/dip/eda/compute/computations/univariate/time-step';
export * from './src/main/java/com/dataiku/dip/analysis/model/core/model-user-meta';
export * from './src/main/java/com/dataiku/dip/analysis/model/prediction/deep-hub-column-format';
export * from './src/main/java/com/dataiku/dip/analysis/model/model-train-info';
export * from './src/main/java/com/dataiku/dip/eda/compute/distributions/log-normal';